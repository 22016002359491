import type { APIMatchRelation, IAPIJobForUser } from '@hokify/common';

export type ListType = {
	type: 'matchrelation';
	identifier: string;
	relation: APIMatchRelation;
};

export interface IRelationsState {
	discarded: any[];
	searchresults: {
		totalJobs: number;
		list: ListType[];
	};
	suggestionsCurrentlyLoading: boolean;
	fullyLoaded: { [jobNr: number]: IAPIJobForUser }; // used to store fully loaded jobs
	lastIdentifier: string | undefined;
}

export const defaultState = (): IRelationsState => ({
	discarded: [],
	searchresults: {
		totalJobs: 0,
		list: []
	},
	suggestionsCurrentlyLoading: false,
	fullyLoaded: {}, // used to store fully loaded relations
	lastIdentifier: undefined
});

export default defaultState;
