import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../src/store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../src/store/types.ts'), 'types.ts')
  resolveStoreModules(require('../src/store/about/actions.ts'), 'about/actions.ts')
  resolveStoreModules(require('../src/store/b2b-content/actions.ts'), 'b2b-content/actions.ts')
  resolveStoreModules(require('../src/store/b2b-content/getters.ts'), 'b2b-content/getters.ts')
  resolveStoreModules(require('../src/store/b2b-content/mutations.ts'), 'b2b-content/mutations.ts')
  resolveStoreModules(require('../src/store/b2b-content/state.ts'), 'b2b-content/state.ts')
  resolveStoreModules(require('../src/store/b2b-job-onboarding/actions.ts'), 'b2b-job-onboarding/actions.ts')
  resolveStoreModules(require('../src/store/business/actions.ts'), 'business/actions.ts')
  resolveStoreModules(require('../src/store/business/mutations.ts'), 'business/mutations.ts')
  resolveStoreModules(require('../src/store/business/state.ts'), 'business/state.ts')
  resolveStoreModules(require('../src/store/cache/actions.ts'), 'cache/actions.ts')
  resolveStoreModules(require('../src/store/cache/mutations.ts'), 'cache/mutations.ts')
  resolveStoreModules(require('../src/store/cache/state.ts'), 'cache/state.ts')
  resolveStoreModules(require('../src/store/company/actions.ts'), 'company/actions.ts')
  resolveStoreModules(require('../src/store/company/mutations.ts'), 'company/mutations.ts')
  resolveStoreModules(require('../src/store/company/state.ts'), 'company/state.ts')
  resolveStoreModules(require('../src/store/cv/actions.ts'), 'cv/actions.ts')
  resolveStoreModules(require('../src/store/download-links/actions.ts'), 'download-links/actions.ts')
  resolveStoreModules(require('../src/store/faq/actions.ts'), 'faq/actions.ts')
  resolveStoreModules(require('../src/store/faq/mutations.ts'), 'faq/mutations.ts')
  resolveStoreModules(require('../src/store/faq/state.ts'), 'faq/state.ts')
  resolveStoreModules(require('../src/store/feature-flag/actions.ts'), 'feature-flag/actions.ts')
  resolveStoreModules(require('../src/store/feature-flag/mutations.ts'), 'feature-flag/mutations.ts')
  resolveStoreModules(require('../src/store/feature-flag/state.ts'), 'feature-flag/state.ts')
  resolveStoreModules(require('../src/store/featureadvertisement/actions.ts'), 'featureadvertisement/actions.ts')
  resolveStoreModules(require('../src/store/featureadvertisement/mutations.ts'), 'featureadvertisement/mutations.ts')
  resolveStoreModules(require('../src/store/featureadvertisement/state.ts'), 'featureadvertisement/state.ts')
  resolveStoreModules(require('../src/store/feedback/actions.ts'), 'feedback/actions.ts')
  resolveStoreModules(require('../src/store/general/actions.ts'), 'general/actions.ts')
  resolveStoreModules(require('../src/store/gnc/actions.ts'), 'gnc/actions.ts')
  resolveStoreModules(require('../src/store/jobalarm/actions.ts'), 'jobalarm/actions.ts')
  resolveStoreModules(require('../src/store/jobfilter/actions.ts'), 'jobfilter/actions.ts')
  resolveStoreModules(require('../src/store/jobfilter/mutations.ts'), 'jobfilter/mutations.ts')
  resolveStoreModules(require('../src/store/jobfilter/state.ts'), 'jobfilter/state.ts')
  resolveStoreModules(require('../src/store/jobs/actions.ts'), 'jobs/actions.ts')
  resolveStoreModules(require('../src/store/jobs/mutations.ts'), 'jobs/mutations.ts')
  resolveStoreModules(require('../src/store/jobs/state.ts'), 'jobs/state.ts')
  resolveStoreModules(require('../src/store/location/actions.ts'), 'location/actions.ts')
  resolveStoreModules(require('../src/store/login/actions.ts'), 'login/actions.ts')
  resolveStoreModules(require('../src/store/login/mutations.ts'), 'login/mutations.ts')
  resolveStoreModules(require('../src/store/login/state.ts'), 'login/state.ts')
  resolveStoreModules(require('../src/store/magazine/actions.ts'), 'magazine/actions.ts')
  resolveStoreModules(require('../src/store/magazine/getters.ts'), 'magazine/getters.ts')
  resolveStoreModules(require('../src/store/magazine/mutations.ts'), 'magazine/mutations.ts')
  resolveStoreModules(require('../src/store/magazine/state.ts'), 'magazine/state.ts')
  resolveStoreModules(require('../src/store/matches/actions.ts'), 'matches/actions.ts')
  resolveStoreModules(require('../src/store/matches/getters.ts'), 'matches/getters.ts')
  resolveStoreModules(require('../src/store/matches/mutations.ts'), 'matches/mutations.ts')
  resolveStoreModules(require('../src/store/matches/state.ts'), 'matches/state.ts')
  resolveStoreModules(require('../src/store/notification/actions.ts'), 'notification/actions.ts')
  resolveStoreModules(require('../src/store/pricing/actions.ts'), 'pricing/actions.ts')
  resolveStoreModules(require('../src/store/pricing/getters.ts'), 'pricing/getters.ts')
  resolveStoreModules(require('../src/store/pricing/mutations.ts'), 'pricing/mutations.ts')
  resolveStoreModules(require('../src/store/pricing/state.ts'), 'pricing/state.ts')
  resolveStoreModules(require('../src/store/quiz/actions.ts'), 'quiz/actions.ts')
  resolveStoreModules(require('../src/store/quiz/state.ts'), 'quiz/state.ts')
  resolveStoreModules(require('../src/store/relations/actions.ts'), 'relations/actions.ts')
  resolveStoreModules(require('../src/store/relations/state.ts'), 'relations/state.ts')
  resolveStoreModules(require('../src/store/team/actions.ts'), 'team/actions.ts')
  resolveStoreModules(require('../src/store/uistate/mutations.ts'), 'uistate/mutations.ts')
  resolveStoreModules(require('../src/store/uistate/state.ts'), 'uistate/state.ts')
  resolveStoreModules(require('../src/store/values/actions.ts'), 'values/actions.ts')
  resolveStoreModules(require('../src/store/values/mutations.ts'), 'values/mutations.ts')
  resolveStoreModules(require('../src/store/values/state.ts'), 'values/state.ts')
  resolveStoreModules(require('../src/store/b2b-job-onboarding/signup/actions.ts'), 'b2b-job-onboarding/signup/actions.ts')
  resolveStoreModules(require('../src/store/company/payment/actions.ts'), 'company/payment/actions.ts')
  resolveStoreModules(require('../src/store/user/landingpage/actions.ts'), 'user/landingpage/actions.ts')
  resolveStoreModules(require('../src/store/user/landingpage/getters.ts'), 'user/landingpage/getters.ts')
  resolveStoreModules(require('../src/store/user/landingpage/mutations.ts'), 'user/landingpage/mutations.ts')
  resolveStoreModules(require('../src/store/user/landingpage/state.ts'), 'user/landingpage/state.ts')
  resolveStoreModules(require('../src/store/user/profile/actions.ts'), 'user/profile/actions.ts')
  resolveStoreModules(require('../src/store/user/profile/mutations.ts'), 'user/profile/mutations.ts')
  resolveStoreModules(require('../src/store/user/profile/state.ts'), 'user/profile/state.ts')
  resolveStoreModules(require('../src/store/user/profile/general/actions.ts'), 'user/profile/general/actions.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
