
import { Component, Vue } from 'vue-property-decorator';
import WebsiteMain from '~/components/website/WebsiteMain.vue';
import WebsiteHeader from '~/components/website/header/WebsiteHeader.vue';

@Component({
	name: 'PlainUserLayout',
	components: {
		WebsiteMain,
		WebsiteHeader,
		WebsiteFooter: () => import('~/components/website/footer/WebsiteFooter.vue')
	}
})
export default class PlainUserLayout extends Vue {}
