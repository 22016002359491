import type { GetterTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IMagazineState } from '~/store/magazine/state';

const articleCategories: Record<string, string> = {
	arbeitsmarkt: 'Aktuelles zum Arbeitsmarkt',
	arbeitsrecht: 'Arbeitsalltag & Arbeitsrecht',
	'ausbildung-lehre': 'Ausbildung & Lehre',
	bewerbung: 'Bewerbung & Lebenslauf',
	jobsuche: 'Alles zur Jobsuche',
	'product-updates': 'Neuigkeiten von hokify'
};

export const getters: GetterTree<IMagazineState, IRootState> = {
	getUserCategories(state) {
		// const categoriesWithArticles = state.articleTeasers.filter(category => category.length > 0);
		return Object.keys(state.articleTeasers).map(key => ({
			url: key,
			name: articleCategories[key]
		}));
	}
};

export default getters;
