import type {
	IAPIFeatureAdvertisementSimple,
	IAPIFeatureAdvertisementExtended
} from '@hokify/common';

export interface IFeatureState {
	features: IAPIFeatureAdvertisementSimple[] | IAPIFeatureAdvertisementExtended[];
}

export const defaultState = (): IFeatureState => ({
	features: []
});

export default defaultState;
