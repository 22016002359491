import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { ICacheState } from '~/store/cache/state';

const cacheDuration = {
	marketingValues: 3 * 60 * 60 * 1000 // cache validate time in milliseconds
};

export const actions: ActionTree<ICacheState, IRootState> = {
	async loadMarketingValues({ state, commit }) {
		if (state.expirationDate < Date.now()) {
			const marketingValues = await this.$axios.$get(`/website-api/general/marketing-values`);
			commit('updateExpirationDate', Date.now() + cacheDuration.marketingValues);
			commit('updateMarketingValues', marketingValues);
		}
		return state.marketingValues;
	}
};

export default actions;
