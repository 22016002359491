import { IAPIPageDownloadLinks } from '@hokify/common';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async loadDownloadLinks(): Promise<IAPIPageDownloadLinks> {
		const result = await this.$axios.$get('website-api/general/download-links');
		return result;
	}
};

export default actions;
