
/**
 * A detailed documentation can be found in PopupHandler.md
 */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IAPIEmployee } from '@hokify/common';
import type { ILoginFormData } from '@hokify/login-stack/lib/types/login';
import type { IJobalarmData, ISendjobData, IPageInfo } from '~/store/types';

const pageInfo: { [name: string]: IPageInfo } = {
	contact: {
		title: 'Kontaktformular', // title that is shown on the top of the opened insidePage
		modal: 'contactform', // name with which the modal is being opened and closed (e.g. $modal.show('contactform'))
		inside: 'loginform', // has to be the same as the page component that is opened via handleClick (located in '/components/insidepages')
		name: 'contact-form-page', // the url pattern used for the inside page (e.g. '/business#contact-form-page-3633')
		component: 'LoginForm' // the actual component being used inside the modal / insidepage (dynamically loaded in template via component :is="options.component")
	},
	jobalarm: {
		title: 'Jobalarm aktivieren',
		modal: 'jobalarmform',
		inside: 'jobalarmform',
		name: 'jobalarm-form-page',
		component: 'JobalarmForm'
	},
	sendjob: {
		title: 'Job zusenden',
		modal: 'sendjobform',
		inside: 'sendjobform',
		name: 'sendjob-form-page',
		component: 'SendjobForm'
	},
	teammember: {
		title: 'Alles über...',
		modal: 'teammember',
		inside: 'teammember',
		name: 'teammember-page',
		component: 'TeamMemberDetail'
	},
	login: {
		title: 'Anmelden oder Registrieren',
		modal: 'loginform',
		inside: 'loginform',
		name: 'login-form-page',
		component: 'LoginForm'
	}
};
function hashCode(str) {
	let hash = 0;
	let i = 0;
	const len = str.length;
	while (i < len) {
		// eslint-disable-next-line no-plusplus,no-bitwise
		hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
	}
	return hash;
}

@Component({
	name: 'PopupHandler',
	components: {
		JobalarmForm: () => import('~/components/website/forms/JobalarmForm.vue'),
		SendjobForm: () => import('~/components/website/forms/SendjobForm.vue'),
		TeamMemberDetail: () => import('~/components/website/TeamMemberDetail.vue'),
		LoginForm: () => import('~/components/website/forms/LoginForm.vue')
	}
})
export default class PopupHandler extends Vue {
	@Prop({ type: Object, required: false }) readonly dataObj!:
		| IJobalarmData
		| ISendjobData
		| IAPIEmployee
		| ILoginFormData;

	@Watch('dataObj')
	dataObjChanged() {
		this.checkIfInstall();
	}

	@Prop({ type: [Number, String], required: false, default: 500 }) readonly width!: number | string;

	@Prop({ type: [Number, String] }) readonly height!: number | string;

	@Prop({
		type: String,
		default: 'signup',
		validator: (value: string) => Object.keys(pageInfo).includes(value)
	})
	readonly mode!: string;

	checkIfInstall() {
		if (!this.installModal) {
			/**
			 * this ensures that popuphandler for the same modal is only registered once, in case the
			 * popuphandler occurs more than once on a page
			 */
			const installModal = !PopupHandler.installedModals[this.options.modal];
			PopupHandler.installedModals[this.options.modal] = true;
			this.installModal = installModal;
		}
	}

	static installedModals = {};

	installModal = false;

	hasClicked = false;

	// return the dynamic options object based on the selected mode (e.g. 'jobalarm')
	get options() {
		return {
			modal: `${pageInfo[this.mode].modal}:${hashCode(JSON.stringify(this.dataObj))}:${Math.floor(
				Date.now() / 1000
			)}`,
			inside: pageInfo[this.mode].inside,
			name: pageInfo[this.mode].name,
			component: pageInfo[this.mode].component,
			pageTitle: pageInfo[this.mode].title
		};
	}

	async closeForm() {
		if (this.$isMobile.any) {
			await this.$page.goBack();
		} else {
			this.$modal.hide(this.options.modal);
		}
		this.$emit('close-button');
	}

	async notify(option: { close?: boolean; status: string }, params?: any) {
		if (option.close) {
			await this.closeForm();
		}
		this.$emit('send-status', option.status, params);
	}

	async handleClick() {
		this.checkIfInstall();
		this.hasClicked = true;
		if (!this.$isMobile.any) {
			// either opens a modal on desktop
			await this.$modal.show(this.options.modal);
		} else {
			// or an insidepage on mobile devices
			await this.$nuxt.$loading.start();
			return import(`~/components/insidepages/${this.options.inside}.vue`)
				.then(async Form => {
					await this.$page.push(
						Form,
						{
							dataObj: this.dataObj
						},
						{
							modalHeading: this.options.pageTitle,
							mode: 'modal',
							name: this.options.name,
							on: {
								notify: this.notify
							},
							done: this.closeForm,
							slots: this.$slots
						}
					);
					this.$nuxt.$loading.finish();
				})
				.catch(err => this.$errorHandler.call(this, err));
		}
	}
}
