/* globals google */
import { bindProps, getPropsValues } from 'gmap-vue/dist/utils/bind-props';
import downArrowSimulator from 'gmap-vue/dist/utils/simulate-arrow-down';
import mappedPropsToVueProps from 'gmap-vue/dist/utils/mapped-props-to-vue-props';

const mappedProps = {
	bounds: {
		type: Object
	},
	componentRestrictions: {
		type: Object,
		// Do not bind -- must check for undefined
		// in the property
		noBind: true
	},
	types: {
		type: Array,
		default() {
			return [];
		}
	}
};

const props = {
	selectFirstOnEnter: {
		required: false,
		type: Boolean,
		default: false
	},
	options: {
		type: Object
	}
};

export default {
	beforeDestroy() {
		if (this.mutationObserver) {
			// if there is still a mutation observer, it's time now to go home...
			this.mutationObserver.disconnect();
		}
		if (this.autocompleteListener) {
			google.maps.event.removeListener(this.autocompleteListener);
		}
		if (this.$autocomplete) {
			this.$autocomplete.unbindAll();
			google.maps.event.clearInstanceListeners(this.$autocomplete);
		}
		if (this.insertedStyleTag) {
			try {
				this.insertedStyleTag.remove();
			} catch (err) {
				// ignore error if browser does not support remove() on html nodes
			}
		}
	},
	mounted() {
		this.$gmapApiPromiseLazy().then(() => {
			if (this.selectFirstOnEnter && this.$refs.input) {
				downArrowSimulator(this.$refs.input);
			}

			if (typeof google.maps.places.Autocomplete !== 'function') {
				throw new TypeError(
					"google.maps.places.Autocomplete is undefined. Did you add 'places' to libraries when loading Google Maps?"
				);
			}

			/* eslint-disable no-unused-vars */
			const finalOptions = {
				...getPropsValues(this, mappedProps),
				...this.options
			};

			try {
				// watch for inserted style tags
				this.mutationObserver = new MutationObserver(mutations => {
					mutations.forEach(mutation => {
						if (mutation.addedNodes) {
							for (let i = 0; i < mutation.addedNodes.length; ++i) {
								if (mutation.addedNodes[i].tagName === 'STYLE') {
									// google maps inserted a new style tag, remove after destroy
									this.insertedStyleTag = mutation.addedNodes[i];
									if (this.mutationObserver) {
										this.mutationObserver.disconnect();
									}
									delete this.mutationObserver;
								}
							}
						}
					});
				});
				this.mutationObserver.observe(document.head, {
					// attributes: true,
					// characterData: true,
					childList: true
					// subtree: true,
					// attributeOldValue: true,
					// characterDataOldValue: true
				});
			} catch (err) {
				// mutation obseve failed
				console.error(err);
			}

			this.$autocomplete = new google.maps.places.Autocomplete(this.$refs.input, finalOptions);

			// mutationObserver.disconnect();

			bindProps(this, this.$autocomplete, mappedProps);

			this.$watch('componentRestrictions', v => {
				if (v !== undefined) {
					this.$autocomplete.setComponentRestrictions(v);
				}
			});

			// Not using `bindEvents` because we also want
			// to return the result of `getPlace()`
			this.autocompleteListener = this.$autocomplete.addListener('place_changed', () => {
				this.$emit('place_changed', this.$autocomplete.getPlace(), this.$refs.input.value);
			});
		});
	},
	props: {
		...mappedPropsToVueProps(mappedProps),
		...props
	}
};
