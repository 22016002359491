import type { MutationTree } from 'vuex';
import type { IAPIPackageVariant } from '@hokify/common';
import type { IPriceListState } from './state';

export const mutations: MutationTree<IPriceListState> = {
	savePricelist(state, pricelist: IAPIPackageVariant[]) {
		state.pricelist = pricelist;
	}
};

export default mutations;
