/* this is our generic error-handler, that we use through all our apps - might be handy to have in the future too
for this to work in the future too, we need to rewrite all occurrences of axios to use fetch instead,
so that it's still usable with nuxt3 */
import Vue from 'vue';
import Axios from 'axios';

export default (ctx, inject) => {
	// if customMessage is set to null, no snack will be shown at all
	const errorHandler = function errorHandler(this: Vue, err: any, customMessage?: string | null) {
		if (process.env.development) {
			console.error('$errorHandler', err, this, customMessage);
		}

		// ensure $loading is false
		if (this.$nuxt && this.$nuxt.$loading) {
			this.$nuxt.$loading.finish();
		}

		if (process.server) {
			throw new Error(err);
		}

		const $snack = this.$snack || Vue.snack;

		if (ctx.deviceOffline) {
			$snack.danger({
				title: 'Keine Internetverbindung!',
				text: 'Bitte überprüfe deine Verbindung zum Internet.'
			});
		} else if (err && err.code === 'ECONNABORTED') {
			$snack.danger({
				title: 'Verbindung unterbrochen!',
				text: 'Bitte überprüfe deine Internetverbindung.'
			});
		} else if (
			/^Loading chunk (\d)+ failed\./.test((err && err.message) || err) &&
			process.client
		) {
			try {
				$snack.danger({
					text: 'Alte Version entdeckt oder anderer schwerwiegender Fehler, bitte hokify neu laden!',
					button: 'Neu laden',
					forever: true,
					action: () => {
						window.location.reload();
					}
				});
			} catch (errSnack) {
				if (this.$sentry) {
					(this.$sentry as any).setExtra('error', errSnack);
					this.$sentry.captureException(errSnack);
				}
				console.error('Chunk failed, fallback error handler failed. Forcing reload...', err);
				window.location.reload();
			}
		} else {
			// decide if we should log this error to sentry
			if (
				err &&
				this.$sentry &&
				// if network error status code > 499 or 422 (invalid parameters)
				((Axios.isAxiosError(err) &&
					(!err.response?.status || err.response.status > 499 || err.response.status === 422)) ||
					// or all other errors except nuxt errors with statusCode > 499
					(!Axios.isAxiosError(err) && (!err.statusCode || err.statusCode > 499)))
			) {
				this.$sentry.withScope(scope => {
					if (Axios.isAxiosError(err)) {
						scope.setExtra('requestUrl', err.config?.url);
						scope.setExtra('requesetMethod', err.config?.method);
						scope.setExtra('responseStatusCode', err.response?.status);
					}
					this.$sentry.captureException(err);
				});
			}

			if (customMessage !== null) {
				$snack.danger({
					text: customMessage || (err && err.message) || 'Seite konnte nicht geladen werden.'
				});
			}
		}
	};

	inject('errorHandler', errorHandler);
};
