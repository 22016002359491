var render = function render(_c,_vm){return (
			_vm.$options.methods.externalLink(
				_vm.props.to,
				_vm.props.target,
				_vm.$options.methods.getDomainRegion(_vm.props.domainRegion, _vm.parent)
			)
		)?_c('a',_vm._g(_vm._b({class:_vm.$options.methods.linkClasses(_vm.props.styling, [_vm.data.class, _vm.data.staticClass]),attrs:{"id":_vm.props.id,"href":_vm.$options.methods.externalLink(
				_vm.props.to,
				_vm.props.target,
				_vm.$options.methods.getDomainRegion(_vm.props.domainRegion, _vm.parent)
			),"name":_vm.props.name,"target":"_blank","rel":_vm.$options.methods.externalRel(_vm.props.to),"aria-label":_vm.props.ariaLabel},on:{"click":function($event){_vm.$options.methods.startInappBrowser(
				$event,
				_vm.$options.methods.externalLink(
					_vm.props.to,
					_vm.props.target,
					_vm.$options.methods.getDomainRegion(_vm.props.domainRegion, _vm.parent)
				)
			)}}},'a',_vm.data.attrs,false),_vm.listeners),[_vm._t("default")],2):(
			_vm.props.to &&
			!_vm.$options.methods.isAppLink(_vm.$options.methods.toLink(_vm.props.to, _vm.parent), _vm.props.linkType, _vm.parent)
		)?_c('a',_vm._g(_vm._b({class:_vm.$options.methods.linkClasses(_vm.props.styling, [_vm.data.class, _vm.data.staticClass]),attrs:{"id":_vm.props.id,"href":_vm.props.to,"name":_vm.props.name,"aria-label":_vm.props.ariaLabel}},'a',_vm.data.attrs,false),_vm.listeners),[_vm._t("default")],2):(_vm.props.to)?_c('nuxt-link',_vm._g(_vm._b({class:_vm.$options.methods.linkClasses(_vm.props.styling, [_vm.data.class, _vm.data.staticClass]),attrs:{"id":_vm.props.id,"to":_vm.$options.methods.toLink(_vm.props.to, _vm.parent),"name":_vm.props.name,"target":_vm.props.target,"aria-label":_vm.props.ariaLabel}},'nuxt-link',_vm.data.attrs,false),_vm.listeners),[_vm._t("default")],2):_c('span',_vm._g(_vm._b({class:_vm.$options.methods.linkClasses(_vm.props.styling, [_vm.data.class, _vm.data.staticClass]),attrs:{"id":_vm.props.id,"to":_vm.props.to,"name":_vm.props.name,"target":_vm.props.target,"data-no-to":"true"}},'span',_vm.data.attrs,false),_vm.listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }