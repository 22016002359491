import Vue from 'vue';

import HokImg from '@hokify/shared-components/lib/components/HokImg.vue';
import HokBgImg from '@hokify/shared-components/lib/components/HokBgImg.vue';
import HokButton from '@hokify/shared-components/lib/components/HokButton.vue';
import HokLink from '@hokify/shared-components/lib/components/HokLink.vue';
import HokInput from '@hokify/shared-components/lib/components/HokInput.vue';
import HokSelect from '@hokify/shared-components/lib/components/HokSelect.vue';
import LazyHydrate from 'vue-lazy-hydration';

// HOK INPUT
Vue.component('HokInput', HokInput);

// HOK LINK
Vue.component('HokLink', HokLink);

// HOK IMG
Vue.component('HokImg', HokImg);

// HOK BG IMG
Vue.component('HokBgImg', HokBgImg);

// HOK BUTTON
Vue.component('HokButton', HokButton);

// HOK SELECT
Vue.component('HokSelect', HokSelect);

// HOK ICON
Vue.component('HokIcon', () => import('@hokify/shared-components/lib/components/HokIcon.vue'));

// (async / low prio) SPINNER
Vue.component('Spinner', () => import('@hokify/shared-components/lib/components/Spinner.vue'));

Vue.component('LazyHydrate', LazyHydrate);
