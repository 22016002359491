import { IAPIPageAbout } from '@hokify/common';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async loadEventList(): Promise<IAPIPageAbout> {
		const result = await this.$axios.$get(`/website-api/general/about`);
		return result;
	}
};

export default actions;
