import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IQuizState } from '~/store/quiz/state';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;

export const actions: ActionTree<IQuizState, IRootState> = {
	async loadQuestions(_, { quizType }: { quizType: string }) {
		return this.$axios.$get(`app-api/jobseeker/quiz?type=${quizType}`, { baseURL });
	},

	async sendAnsweredQuestions(
		_,
		payload: { questionId: string; answerId: string }[]
	): Promise<{ success: boolean }> {
		return this.$axios.$post('app-api/jobseeker/quiz', payload, { baseURL });
	}
};

export default actions;
