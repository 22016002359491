import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { IBusinessState } from '~/store/business/state';

export const mutations: MutationTree<IBusinessState> = {
	updateFaqSectionList(state, faqSectionList) {
		Vue.set(state, 'faqSectionList', faqSectionList);
	},
	updateTutorialSectionList(state, tutorialSectionList) {
		Vue.set(state, 'tutorialSectionList', tutorialSectionList);
	},
	updateLandingPageData(state, landingPageData) {
		Vue.set(state, 'landingPageData', landingPageData);
	}
};

export default mutations;
