import { IAPIMarketingValues } from '@hokify/common';

export interface ICacheState {
	marketingValues: IAPIMarketingValues;
	expirationDate: number;
}
export const defaultState = (): ICacheState => ({
	marketingValues: {
		candidatesPlaced: '',
		averageSecondsApplication: '',
		monthlyUsers: '',
		yearlyActiveJobseekers: '',
		publishingUsers: '',
		popularLandingPages: [],
		startingPrice: 0,
		applicationsInTotal: '',
		jobsOnline: 0
	},
	expirationDate: 0
});

export default defaultState;
