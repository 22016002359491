import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { IAPILoginUser, APITypeObjectId } from '@hokify/common';
import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { isObjectId } from '@hokify/shared-components/lib/helpers/utility';
import { completeObj } from '~/helpers/user';
import type { IUserProfileState } from './state';
import { defaultState } from './state';

export function isAPILoginUser(obj: any): obj is IAPILoginUser {
	return !!(obj && obj._id);
}

const numberRegEx = /^\d+$/;

function isValidIntegerNumber(number: any): boolean {
	return parseInt(number, 10) === number || numberRegEx.test(number);
}

export function setPropByPath(obj, propertyPath, value) {
	if (!propertyPath) {
		return;
	}

	const props = propertyPath.split('.');
	let candidate;
	let parentObj = obj;

	for (let i = 0; i < props.length; i += 1) {
		let prop = props[i];

		let indexValue;
		let found = -1;
		let index: number | APITypeObjectId<any> | undefined;

		// cut away array brackets
		let elementType: 'object' | 'array' = 'object';
		if (prop.includes('[') && prop.includes(']')) {
			elementType = 'array';
			indexValue = prop.substr(prop.indexOf('[') + 1, prop.indexOf(']') - prop.indexOf('[') - 1);

			index =
				(isValidIntegerNumber(indexValue) && parseInt(indexValue, 10)) ||
				(isObjectId(indexValue) && indexValue) ||
				undefined;

			if (indexValue && index === undefined) {
				throw new Error(`invalid index value for ${prop}`);
			}
			prop = prop.substr(0, prop.indexOf('['));
		}

		if (!obj) {
			break;
		}

		// create object path if it does not exists
		if (!obj[prop] && i < props.length) {
			Vue.set(obj, prop, elementType === 'array' ? [] : {});
		}

		parentObj = obj;
		candidate = obj[prop];

		if (candidate !== undefined) {
			if (index === undefined) {
				obj = candidate;
			} else if (typeof index === 'number') {
				obj = candidate[index];
			} else if (index) {
				obj = candidate.find(c => index && c._id.toString() === index.toString());
				found = candidate.indexOf(obj);
			}
		}
		// we are at the end
		if (i === props.length - 1) {
			if (value && elementType === 'array') {
				const completeValue =
					value && typeof value === 'object' ? { _id: indexValue, ...value } : value;
				if (found >= 0) {
					// Vue.set(obj, indexValue, value); // vue.set needed?
					candidate[found] = completeValue;
				} else {
					candidate.push(completeValue);
				}
			} else if (found >= 0) {
				if (candidate !== null) {
					candidate.splice(found, 1);
				}
			} else if (value === null) {
				Vue.set(parentObj, prop, undefined);
			} else {
				// parentObj[prop] = value;
				Vue.set(parentObj, prop, value); // vue.set necessary?
			} // else throw new Error(`invalid update: ${obj}, ${propertyPath},${value}`);
		}
	}
}

export const mutations: MutationTree<IUserProfileState> = {
	setUser(state, { user, versionOkay }): void {
		Vue.set(state, 'obj', completeObj(user, 'user'));
		Vue.set(state, 'versionOkay', versionOkay);

		if (user && lsTest()) {
			localStorage.setItem('mode', user.mode);
		}
	},
	delElement(state, delObj): void {
		const obj = delObj.path.split('.').reduce((o, i) => o[i], state.obj);

		if (!obj) {
			return;
		}

		if (Array.isArray(obj)) {
			obj.some((oExist, index) => {
				if (
					(typeof delObj.index !== 'undefined' && delObj.index === index) ||
					(typeof delObj._id !== 'undefined' && oExist._id === delObj._id)
				) {
					obj.splice(index, 1);
					return true;
				}
				return false;
			});
			return;
		}

		console.warn(`path ${delObj.path} is not an array, canceling del`);
	},
	addElement(state, addObj): void {
		let obj = addObj.path.split('.').reduce((o, i) => o[i], state.obj);
		if (!obj) {
			// add empty array on this
			const rootPath = addObj.path.split('.');
			const lastPath = rootPath.pop();

			const objRoot = rootPath.reduce((o, i) => o[i], state.obj);
			// set empty array
			Vue.set(objRoot, lastPath, []);
			obj = objRoot[lastPath];
		}

		if (Array.isArray(obj)) {
			obj.push(addObj.obj);
			return;
		}

		console.warn(`path ${addObj.path} is not an array, canceling add`);
	},
	updateElements(state, userUpdate): void {
		Object.keys(userUpdate).forEach(path => {
			setPropByPath(state.obj, path, userUpdate[path]);
		});
	},
	updateElement(state, updateObj): void {
		if (Object.keys(updateObj.update).length === 0) {
			console.warn('skipping empty updatePath update', updateObj);
			return;
		}

		const obj = updateObj.path.split('.').reduce((o, i) => o[i], state.obj);

		if (Array.isArray(obj)) {
			// update existing entry
			if (updateObj._id !== undefined || updateObj.index !== undefined) {
				obj.some((o, index) => {
					if (
						(updateObj.index !== undefined && updateObj.index === index) ||
						(updateObj._id !== undefined && o._id === updateObj._id)
					) {
						Vue.set(obj, index, { ...o, ...updateObj.update }); // replace with new obj - so VUE detects the chagnes
						return true;
					}
					return false;
				});
				return;
			}

			// add new array entry
			obj.push(updateObj.update);
		}

		if (obj !== undefined) {
			Object.keys(updateObj.update).forEach(key => {
				Vue.set(obj, key, updateObj.update[key]);
			});
		}
	},
	update(state, update): void {
		if (!update || Object.keys(update).length === 0) {
			console.warn('skipping empty user obj update');
			return;
		}

		if (!state.obj) {
			// init user obj
			Vue.set(state, 'obj', {});
		}

		Object.keys(update).forEach(key => {
			Vue.set(state.obj as object, key, update[key]);
		});
	},
	updatePrivacy(state, update): void {
		if (!state.obj) {
			// no logged in user
			// no user obj - use temporary variable
			const enablePrivacy = Object.keys(update).some(key => !!update[key]);

			if (!enablePrivacy) {
				// do not create an empty user obj if privacy is set to false
				console.warn('canceling updatePrivacy because all values are false');
				return;
			}

			Vue.set(state, 'obj', {});
		}

		if (!(state.obj as any).privacy) {
			Vue.set(state.obj as object, 'privacy', {});
		}

		Object.keys(update).forEach(key => {
			Vue.set((state.obj as any).privacy, key, update[key]);
		});
	},
	resetState(currentState): void {
		Object.assign(currentState, defaultState());
	}
};

export default mutations;
