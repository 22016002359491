<template>
	<Transition name="snack">
		<div
			v-if="active"
			v-touch="touchHandler()"
			:class="[`${config[theme].primary}`, `${offset || 'bottom-4'}`]"
			class="bottom snackbar"
		>
			<div v-if="title" class="snackbar__title">
				{{ title }}
			</div>
			<div class="flex items-center">
				<div class="snackbar__text">
					{{ text }}
				</div>
				<div
					v-if="button"
					:class="buttonIcon ? 'snackbar__basic text-xs flex' : 'snackbar__action'"
					@click.prevent="action"
				>
					{{ button }}
					<HokIcon v-if="buttonIcon" :size="4" class="ml-1" :icon="buttonIcon" />
				</div>
				<div v-if="close || !button" class="times" data-cy="snackbar-close" @click="$emit('close')">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
						<path
							fill="#E3E3E3"
							d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
						/>
						<path d="M0 0h24v24H0z" fill="none" />
					</svg>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
import Touch from '../../directives/touch';
import HokIcon from '../../components/HokIcon.vue';

export default {
	name: 'Snackbar',
	components: { HokIcon },
	directives: {
		touch: Touch
	},
	props: {
		config: {
			type: Object,
			default: () => ({})
		}
	},
	data: () => ({
		active: false,
		text: '',
		button: '',
		buttonIcon: '',
		close: false,
		title: false,
		offset: '', // add offset straight on snackbar via tailwind bottom class
		theme: 'default',
		action: null
	}),
	methods: {
		touchHandler() {
			return {
				left: () => {
					this.$emit('close');
				},
				down: () => {
					this.$emit('close');
				},
				right: () => {
					this.$emit('close');
				}
			};
		}
	}
};
</script>

<style lang="scss" scoped>
$snackbar-background: #353535;
$snackbar-text: #e3e3e3;

.snackbar {
	z-index: 999999;
	font-size: 1rem;
	background-color: $snackbar-background;
	opacity: 0.8;
	color: $snackbar-text;
	align-items: center;
	justify-content: center;
	position: fixed;
	min-height: $s4;
	padding: $s4;
	border-radius: 8px;
	width: 33%;
	box-shadow: 0px 4px 20px 0px rgba(209, 209, 214, 0.75);
	@media screen and (max-width: $sm) {
		width: calc(100% - 2rem);
		margin-left: 1rem;
		margin-right: 1rem;
	}
	&.bottom {
		@media screen and (min-width: $sm) {
			transform: translateX(-50%);
			left: 50%;
			&-left {
				left: 1rem;
			}
			&-right {
				right: 1rem;
			}
		}
	}

	&.default {
		.snackbar__action {
			color: $color-yellow;
		}
	}
	&.danger {
		.snackbar__action {
			color: $color-purple;
		}
	}
	&.success {
		.snackbar__action {
			color: $color-main;
		}
	}

	.snackbar__title {
		font-weight: bold;
		margin: auto;
		width: 100%;
	}

	.snackbar__text {
		flex: 1;
	}
	.snackbar__action {
		text-transform: uppercase;
		font-weight: 500;
		margin: 0 0 0 1rem;
		cursor: pointer;
		user-select: none;
	}

	.snackbar__basic {
		font-weight: 500;
		margin: 0 0 0 1rem;
		cursor: pointer;
		user-select: none;
	}
	.times {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: $s4;
		width: $s4;
		margin-left: 1rem;
		transform: scale(1.25);
		position: relative;
		&:before {
			display: block;
			content: '';
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.9);
			border-radius: 50%;
			transition: transform 0.1s ease-in;
		}
		&:hover {
			&:before {
				background-color: rgba(255, 255, 255, 0.2);
				transform: scale(2);
			}
		}
	}
}

.snack-enter-active,
.snack-leave-active {
	transition: all 0.4s ease;
}

.snack-enter,
.snack-leave-to {
	opacity: 0;
	@media screen and (min-width: $sm) {
		&.bottom {
			transform: translateY(100%) translateX(-50%);
		}
	}
	&[class*='bottom'] {
		@media screen and (max-width: $sm) {
			transform: translateY(100%);
		}
	}
	&[class*='-left'] {
		@media screen and (min-width: $sm) {
			transform: translateX(-50%);
		}
	}
	&[class*='-right'] {
		@media screen and (min-width: $sm) {
			transform: translateX(50%);
		}
	}
}
</style>
