import { insidePages } from '@hokify/shared-components/lib/plugins/insidepage/insidePage';
import isMobile from 'ismobilejs/cjs';

export function getMatchedComponents(route, matches = false, prop = 'components') {
	return Array.prototype.concat.apply(
		[],
		route.matched.map((m, index) =>
			Object.keys(m[prop]).map(key => {
				if (matches) {
					matches.push(index);
				}
				return m[prop][key];
			})
		)
	);
}

if (process.client) {
	if ('scrollRestoration' in window.history) {
		window.history.scrollRestoration = 'manual';

		// reset scrollRestoration to auto when leaving page, allowing page reload
		// and back-navigation from other pages to use the browser to restore the
		// scrolling position.
		window.addEventListener('beforeunload', () => {
			window.history.scrollRestoration = 'auto';
		});

		// Setting scrollRestoration to manual again when returning to this page.
		window.addEventListener('load', () => {
			window.history.scrollRestoration = 'manual';
		});
	}
}

export default function scrollBehaviour(to, from, savedPosition) {
	// if the returned position is falsy or an empty object,
	// will retain current scroll position.
	let position = false;

	// if we have a body offset, and this was the last inside page,
	// we use this one to restore scrolling offset for the body prop
	if (insidePages.pages.length === 0 && insidePages.bodyScrollOffset !== undefined) {
		savedPosition = { x: 0, y: insidePages.bodyScrollOffset };
		insidePages.bodyScrollOffset = undefined;
	}

	// if no children detected and scrollToTop is not explicitly disabled
	const Pages = getMatchedComponents(to);
	if (Pages.length < 2 && Pages.every(Page => Page.options.scrollToTop !== false)) {
		// scroll to the top of the page
		position = { x: 0, y: 0 };
	} else if (Pages.some(Page => Page.options.scrollToTop)) {
		// if one of the children has scrollToTop option set to true
		position = { x: 0, y: 0 };
	}

	// savedPosition is only available for popstate navigations (back button)
	if (savedPosition) {
		position = savedPosition;
	}

	// start position at joblist if we do a jobsearch on companypage
	if (process.client && to.path.match(/\/c\/((?:\w+-)+\w+)\/(f|kf|lf|mf)\//)) {
		const isMobileInstance = isMobile(navigator?.userAgent || 'unknown');
		position = { selector: '#jobs', offset: { y: isMobileInstance.phone ? 20 : -170 } };
	}

	const nuxt = window.$nuxt;

	// triggerScroll is only fired when a new component is loaded
	if (to.path === from.path && to.hash !== from.hash) {
		// eslint-disable-next-line vue/custom-event-name-casing
		nuxt.$nextTick(() => nuxt.$emit('triggerScroll'));
	}

	return new Promise(resolve => {
		// wait for the out transition to complete (if necessary)
		nuxt.$once('triggerScroll', () => {
			// coords will be used if no selector is provided,
			// or if the selector didn't match any element.
			if (to.hash) {
				let { hash } = to;
				// CSS.escape() is not supported with IE and Edge.
				if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
					hash = `#${window.CSS.escape(hash.substr(1))}`;
				}
				try {
					// if hash refers to jobdetail page dont use hash as selector
					if (hash.includes('job-')) {
						position = savedPosition || 0;
					} else if (document.querySelector(hash)) {
						// scroll to anchor by returning the selector
						position = { selector: hash };
					}
				} catch (e) {
					console.warn(
						'Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).'
					);
				}
			}
			// Setting timeout to fix triggerScroll being fired too early
			// https://github.com/nuxt/nuxt/issues/1376#issuecomment-372900702
			setTimeout(() => {
				resolve(position);
			}, 50);
		});
	});
}
