
import { Component, Vue } from 'vue-property-decorator';

@Component({
	name: 'WebToAppBanner',
	components: {}
})
export default class WebToAppBanner extends Vue {
	appstoreLink = `https://67ra.adj.st/?adjust_t=o4bwle`;

	goToApp(): void {
		this.$trackWebsiteEvent('click_get_app_startpage', {});
		window.open(this.appstoreLink);
	}

	closeBar() {
		this.$store.commit('uistate/updateHasClosedAppBanner', { hasClosedAppBanner: true });
	}
}
