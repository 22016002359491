import type { MutationTree } from 'vuex';
import { IFeatureState } from '~/store/featureadvertisement/state';

export const mutations: MutationTree<IFeatureState> = {
	saveFeatures(state, features) {
		state.features = features;
	}
};

export default mutations;
