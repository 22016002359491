import { getCookie } from '@hokify/shared-components/lib/helpers/cookie';
import { getGoogleAnalyticsClientId } from '@hokify/tracking/tracking.client';
import type { APITypeObjectId, APIObjectType, IAPIMatchForJobSeeker } from '@hokify/common';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IRelationsState } from './state';

export const actions: ActionTree<IRelationsState, IRootState> = {
	async saveRelation({ commit }, relation) {
		const cookies = process.client && window.document.cookie;
		const utm = (cookies && getCookie('utm', cookies)) || undefined;
		const result = await this.$axios.$post<{
			matchObj?: IAPIMatchForJobSeeker;
			action: 'contact' | 'applied' | 'pending' | 'saved' | 'discard' | 'needreview';
			matchId?: APITypeObjectId<APIObjectType.Match>;
		}>('/app-api/jobseeker/match-action/save', {
			relation: relation.type,
			relationId: relation.obj._id,
			audienceId: relation.audienceId,
			utm,
			gaCID: await getGoogleAnalyticsClientId(),
			fbp: (cookies && getCookie('_fbp', cookies)) || undefined,
			fbc: (cookies && getCookie('_fbc', cookies)) || undefined
		});

		if (result) {
			const { matchObj } = result;
			// save in store
			if (matchObj) {
				commit('matches/saveMatch', matchObj, { root: true });
				// commit('messages/increaseOstats', 'saved', { root: true }); ostats saved / applied are not used anymore
			}
		}
		return result;
	},

	async discardRelation(_, relation) {
		const cookies = process.client && window.document.cookie;
		const utm = (cookies && getCookie('utm', cookies)) || undefined;

		const result = await this.$axios.$post<{
			matchObj?: IAPIMatchForJobSeeker;
			action: 'contact' | 'applied' | 'pending' | 'saved' | 'discard';
			matchId?: APITypeObjectId<APIObjectType.Match>;
		}>('/app-api/jobseeker/match-action/discard', {
			relation: relation.type,
			relationId: relation.obj._id,
			audienceId: relation.audienceId,
			utm,
			gaCID: await getGoogleAnalyticsClientId(),
			fbp: (cookies && getCookie('_fbp', cookies)) || undefined,
			fbc: (cookies && getCookie('_fbc', cookies)) || undefined
		});
		return result;
	},

	resetState: {
		root: true,
		handler({ commit }) {
			commit('resetState');
		}
	}
};

export default actions;
