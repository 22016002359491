/* we use this plugin to only load the js-library and the map itself, after the user clicked on the before statically
created image. this has 2 reasons: its faster (performance) and its cheaper (less google maps loads) therefore
this is important to keep at it is also for the future (nuxt3). but as this is custom code of a forked library this
most probably won't work with nuxt3, and we will need a new way (library / custom code) to achieve this again */

import Vue from 'vue';
import { loadGmapApi } from 'gmap-vue/dist/main';
import lazy from 'gmap-vue/dist/utils/lazy-value';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface Window {
		vueGoogleMapsInit: any;
	}
}

function makeGmapApiPromiseLazy(options) {
	// Things to do once the API is loaded
	function onApiLoaded() {
		// GmapApi.gmapApi = {};
		// console.log('Google Maps onApiLoaded');
		return window.google;
	}

	if (options.load) {
		// If library should load the API
		return lazy(() => {
			// This will only be evaluated once
			if (typeof window === 'undefined') {
				// server side -- never resolve this promise
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				return new Promise(() => {}).then(onApiLoaded);
			}
			return new Promise((resolve, reject) => {
				try {
					window.vueGoogleMapsInit = resolve;
					loadGmapApi(options.load, options.loadCn);
				} catch (err: any) {
					reject(err);
				}
			}).then(onApiLoaded);
		});
	} // If library should not handle API, provide
	// end-users with the global `vueGoogleMapsInit: () => undefined`
	// when the Google Maps API has been loaded
	const promise = new Promise(resolve => {
		if (typeof window === 'undefined') {
			// Do nothing if run from server-side
			return;
		}
		window.vueGoogleMapsInit = resolve;
	}).then(onApiLoaded);

	return lazy(() => promise);
}

const initGoogleMaps = options => {
	// Set defaults
	options = {
		installComponents: true,
		autobindAllEvents: false,
		...options
	};

	const defaultResizeBus = new Vue();

	// Use a lazy to only load the API when
	// a VGM component is loaded
	const gmapApiPromiseLazy = makeGmapApiPromiseLazy(options);

	Vue.prototype.$gmapDefaultResizeBus = defaultResizeBus;
	Vue.prototype.$gmapApiPromiseLazy = gmapApiPromiseLazy;
	Vue.prototype.$gmapOptions = options;
};

initGoogleMaps({
	load: {
		key: process.env.googleApiKey,
		libraries: 'places,visualization', // necessary for places input
		language: 'de'
	},
	installComponents: false
});
