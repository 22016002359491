import { SupportedDevices } from '@hokify/login-stack/lib/types/supported-devices';
import type { ActionTree, MutationTree } from 'vuex';
import type { IUiStateState } from '~/store/uistate/state';
import type { IJobalarmData } from '~/store/types';
import type { IUserLoginState } from '~/store/login/state';
import { IUserProfileState } from '~/store/user/profile/state';
import { IFeatureFlagState } from './feature-flag/state';

// import { getCookie } from '@hokify/shared-components/lib/helpers/cookie';

export interface IRootState {
	topLevelDomain: string;
	host: string;
	locale: string;
	localeMessages?: string[];
	device: SupportedDevices;
	back: boolean;
	globalNetworkErrors: number;
	addToHomeScreen: boolean;
	setupPushNotification: boolean;

	// landingpage ID - stored here for now
	lpId: number | null;
	jobAlarmId: string;
	jobFilter: IJobalarmData;

	// modules
	featureFlags: IFeatureFlagState;
	login: IUserLoginState;
	user: {
		profile: IUserProfileState;
	};
	uistate: IUiStateState;
}

export const state = (): IRootState =>
	({
		topLevelDomain: '',
		host: '',
		locale: 'de',
		localeMessages: undefined,
		device: 'website',
		back: false,
		globalNetworkErrors: 0,
		addToHomeScreen: false,
		setupPushNotification: false,
		lpId: null,
		jobAlarmId: ''
	}) as IRootState;

export const mutations: MutationTree<IRootState> = {
	back(currentState, val) {
		currentState.back = val;
	},
	setTopLevelDomain(currentState, topLevelDomain) {
		currentState.topLevelDomain = topLevelDomain;
	},
	setHost(currentState, host) {
		currentState.host = host;
	},
	setLocale(currentState, locale) {
		currentState.locale = locale;
	},
	setLocaleMessages(currentState, localeMessages) {
		currentState.localeMessages = localeMessages;
	},

	setJobFilter(currentState, jobFilter) {
		currentState.jobFilter = jobFilter;
	},

	setJobAlarmId(currentState, newId) {
		currentState.jobAlarmId = newId;
	},

	increaseGlobalNetworkErrors(currentState) {
		currentState.globalNetworkErrors += 1;
	},

	resetGlobalNetworkErrors(currentState) {
		currentState.globalNetworkErrors = 0;
	},

	resetState(currentState) {
		Object.assign(currentState, state());
	},
	addToHomeScreen(currentState, val) {
		currentState.addToHomeScreen = !!val;
	},
	setupPushNotification(currentState, val) {
		currentState.setupPushNotification = !!val;
	}
};

interface IPushObj {
	deviceId?: string;
	token?: string;
	deviceType?: string;
	endpoint?: string;
	keys?: {
		p256dh: string;
		auth: string;
	};
}

export const actions: ActionTree<IRootState, IRootState> = {
	/* nuxtServerInit({ commit }, { req, store }) {
		// check local cookies, if they are present and nothing is in the store yet, use them
		const cookies = req.headers && req.headers.cookie;
		if (cookies && !store.state.login.sessionIdCookie) {
			const sessionToken = getCookie(process.env.loginCookie, cookies);

			if (sessionToken) {
				// setting session token
				if (process.env.development) {
					console.log('using sessionToken from cookie (nuxtServerInit)', sessionToken);
				}
				commit('login/sessionIdCookie', { sessionToken }, { root: true });
			}
		}
	}, */
	trackPushEvent({ state: currentState }, oid) {
		return this.$axios.$post('/app-api/notification/track-open', {
			oid,
			device: currentState.device
		});
	},
	refreshPushOnServer({ state: currentState, commit }, subcriptionObject) {
		const pushObj: IPushObj = {};

		if (subcriptionObject.endpoint) {
			pushObj.endpoint = subcriptionObject.endpoint;
		}

		if (subcriptionObject.keys) {
			pushObj.keys = {
				p256dh: subcriptionObject.keys.p256dh,
				auth: subcriptionObject.keys.auth
			};
		}

		const payload = {
			targetType: 'webpush',
			targetParams: pushObj,
			subscription: { filter: currentState.jobFilter }
		};

		this.$axios.$put('/website-api/jobalarm/create', payload).then(result => {
			if (result.jobAlarmId) {
				commit('setJobAlarmId', result.jobAlarmId);
			}
		});
	},
	resetState: {
		root: true,
		handler({ commit }) {
			commit('resetState');
		}
	}
};
