
import { defineComponent } from 'vue';
import HokIcon from './HokIcon.vue';

export default defineComponent({
	components: { HokIcon },
	data() {
		const color = this.stylingWhite ? 'white' : 'purple';
		const icon = 'icon-alert';
		const isInfoBox = false;

		return {
			isInfoBox,
			icon,
			color
		};
	},
	props: {
		type: { type: String, default: '' },
		stylingWhite: { type: Boolean, default: false } // used for a box in business green, where purple would be barely visible
	}
});
