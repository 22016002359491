import type { MutationTree } from 'vuex';
import type { IValuesState } from './state';
import { defaultState } from './state';

export const mutations: MutationTree<IValuesState> = {
	cache(state, cache: { cacheKey: string; data: any }): void {
		state.cache[cache.cacheKey] = cache.data;
	},
	resetState(currentState): void {
		Object.assign(currentState, defaultState());
	}
};

export default mutations;
