import type {
	IAPIWebinarTeaser,
	IAPIWebinar,
	IAPIPodcastTeaser,
	IAPIPodcast
} from '@hokify/common';

export interface IB2BContentState {
	upcomingWebinarTeaser: IAPIWebinarTeaser[];
	pastWebinarTeaser: IAPIWebinarTeaser[];
	webinars: Record<string, IAPIWebinar>;
	podcastTeaser: IAPIPodcastTeaser[];
	podcasts: Record<string, IAPIPodcast>;
}

export const defaultState = (): IB2BContentState => ({
	upcomingWebinarTeaser: [],
	pastWebinarTeaser: [],
	webinars: {},
	podcastTeaser: [],
	podcasts: {}
});

export default defaultState;
