import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async resolveGeoCoords(_, { long, lat }) {
		try {
			const response = await this.$axios.$get(
				`/website-api/general/resolve-geo?lat=${lat}&long=${long}`
			);
			return response.location;
		} catch {
			throw new Error('no geo location found');
		}
	}
};

export default actions;
