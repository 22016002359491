import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async loadCvData() {
		const result = await this.$axios.$get('/website-api/general/cv');
		return result;
	}
};

export default actions;
