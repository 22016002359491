import { EventBus } from '@hokify/shared-components/lib/eventbus';
import type { ActionTree } from 'vuex';
import type { PrivacyType } from '@hokify/shared-components/lib/types/privacyType';
import type { IRootState } from '~/store';
import { isAPILoginUser } from '~/store/user/profile/mutations';
import type { IUserProfileState } from './state';

export const actions: ActionTree<IUserProfileState, IRootState> = {
	async acceptPrivacy({ commit }, type: PrivacyType) {
		const result = await this.$axios.$put('app-api/acceptPrivacy', { privacy: type });
		if (result) {
			commit('user/profile/updatePrivacy', { [type]: true }, { root: true });
			return true;
		}
		return false;
	},
	setUser({ commit }, { user, versionOkay }) {
		commit('setUser', { user, versionOkay });

		if (user && user._id) {
			commit('login/loggedIn', { userId: user._id }, { root: true });
			// process hooks
			if (process.client) {
				EventBus().$emit('logged-in', { user, versionOkay });
			}

			/* Not in use until any kind of message notification is used in webapp (eg showing number of unseen messages over usericon) */
			// dispatch('messages/updateOStats', undefined, { root: true });
		} else {
			commit('login/loggedOut', undefined, { root: true });
		}
	},
	update({ state, commit }, update) {
		commit('update', update);

		if (isAPILoginUser(state.obj)) {
			commit('login/loggedIn', { userId: state.obj._id }, { root: true });
		}
	},
	updateElements({ state, commit }, update) {
		// update userObj
		commit('updateElements', update);

		// make sure loggedIn is set to true
		if (isAPILoginUser(state.obj)) {
			commit('login/loggedIn', { userId: state.obj._id }, { root: true });
		}
	},
	resetState: {
		root: true,
		handler({ commit }) {
			commit('resetState');
		}
	}
};

export default actions;
