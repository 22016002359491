import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { ICacheState } from '~/store/cache/state';

export const mutations: MutationTree<ICacheState> = {
	updateMarketingValues(state, marketingValues) {
		Vue.set(state, 'marketingValues', marketingValues);
	},
	updateExpirationDate(state, expirationDate) {
		Vue.set(state, 'expirationDate', expirationDate);
	}
};

export default mutations;
