import Vue from 'vue';

// TODO check when nuxt 3 is out; actually still needed since https://github.com/nuxt/nuxt.js/issues/3877 still open -> css bleeds through layouts
export default Vue.extend({
	/* marked as functional since component is instanceless and stateless */
	functional: true,
	props: {
		tag: { type: String, required: true }
	},
	/* lack of instance requires context as 2nd argument */
	render(createElement, { props, children }) {
		return createElement(props.tag, { attrs: props }, children);
	}
});

Vue.config.ignoredElements = [...(Vue.config.ignoredElements || []), 'user', 'company', 'error'];
