import type { IAPILoginUser } from '@hokify/common';

export interface IUserProfileState {
	obj?: IAPILoginUser;
	versionOkay: null;
}

export const defaultState = (): IUserProfileState => ({
	obj: undefined,
	versionOkay: null
});

export default defaultState;
