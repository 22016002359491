import { PriceListType } from '@hokify/common';
import type { GetterTree } from 'vuex';
import type { IRootState } from '..';
import type { IPriceListState } from './state';

export const getters: GetterTree<IPriceListState, IRootState> = {
	selectablePricelistPackages: state =>
		state.pricelist?.filter(
			p =>
				p.visible &&
				p.buyablePackage.type === 'buyablePackage' &&
				p.buyablePackage.buyablePackage.type === PriceListType.Job
		)
};

export default getters;
