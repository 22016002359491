import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import { IFeatureState } from '~/store/featureadvertisement/state';
import { IAPIFeatureAdvertisementExtended, IAPIFeatureAdvertisementSimple } from '@hokify/common';

export const actions: ActionTree<IFeatureState, IRootState> = {
	async loadFeatureAdvertisement({
		commit
	}): Promise<IAPIFeatureAdvertisementSimple[] | IAPIFeatureAdvertisementExtended[]> {
		try {
			const features: IAPIFeatureAdvertisementSimple[] | IAPIFeatureAdvertisementExtended[] =
				await this.$axios.$get('website-api/featureadvertisement');
			commit('saveFeatures', features);
			return features;
		} catch (error: any) {
			console.error('get featureadvertisement failed', error);
			throw error.response?.data || error.response || error;
		}
	}
};

export default actions;
