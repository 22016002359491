import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { IJobsState } from '~/store/jobs/state';

export const mutations: MutationTree<IJobsState> = {
	setCustomBackLinkUrl(state, backLinkUrl) {
		Vue.set(state, 'customBackLinkUrl', backLinkUrl);
	},
	setNumberOfSimilarJobs(state, numberOfSimilarJobs) {
		Vue.set(state, 'numberOfSimilarJobs', numberOfSimilarJobs);
	},
	setLastJobPageNumber(state, data) {
		Vue.set(state, 'lastJobPageNumber', data);
	},
	updatePreviewJob(state, previewJob) {
		Vue.set(state, 'previewJob', previewJob);
	},
	setOtherTerms(state, otherTerms) {
		Vue.set(state, 'otherTerms', otherTerms);
	}
};

export default mutations;
