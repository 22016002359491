
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Spinner',
	props: {
		size: {
			type: String,
			default: 'medium',
			validator: (value: string) => ['small', 'medium'].includes(value)
		},
		fixed: { type: Boolean, default: true }
	}
});
