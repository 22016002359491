var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input input--sae mb-4",class:[_vm.myClasses, { 'opacity-50': _vm.disabled }]},[(_vm.$slots.default)?_c('label',{staticClass:"input__label input__label--sae",class:{ 'styling-white': _vm.stylingWhite },attrs:{"for":_vm.id}},[_c('span',{staticClass:"input__label-content input__label-content--sae",class:{ 'styling-white': _vm.stylingWhite }},[_vm._t("default"),_vm._v(" "),(_vm.required)?_c('span',{class:{ required: !_vm.value, 'styling-white': _vm.stylingWhite && !_vm.focused }},[_vm._v("*")]):_vm._e()],2)]):_vm._e(),_vm._v(" "),_c('input',{directives:[{name:"focus",rawName:"v-focus"}],ref:"input",staticClass:"input__field input__field--sae input",class:{
			'typeform-input': _vm.styling === 'typeform',
			'text-center': _vm.styling === 'text-center',
			'bg-white': _vm.styling === 'bg-white',
			'cursor-not-allowed': _vm.disabled,
			'styling-white': _vm.stylingWhite
		},attrs:{"id":_vm.id,"type":_vm.type,"minlength":_vm.minLength,"maxlength":_vm.maxLength,"max":_vm.max,"min":_vm.min,"pattern":_vm.pattern,"inputmode":_vm.inputmode,"name":_vm.name,"autocomplete":!_vm.browserAutocomplete ? 'off' : _vm.browserAutocomplete,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"blur":_vm.blur,"click":function($event){return _vm.$emit('click', $event)},"change":function($event){_vm.$emit('input', $event.target && _vm.hasValue($event.target) && $event.target.value)},"input":function($event){_vm.$emit('input', $event.target && _vm.hasValue($event.target) && $event.target.value)},"focus":_vm.focus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }