import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { genCookie } from '@hokify/shared-components/lib/helpers/cookie';
import type { MutationTree } from 'vuex';
import type { IUserLoginState } from './state';
import { defaultState } from './state';

export const mutations: MutationTree<IUserLoginState> = {
	loggedOut(state): void {
		state.loggedIn = false;
	},
	sessionIdCookie(state, { sessionToken }): void {
		let mySessionToken;
		if (sessionToken === 'false' || !sessionToken) {
			mySessionToken = false;
		} else if (typeof sessionToken === 'string') {
			mySessionToken = sessionToken;
		} else {
			console.warn('invalid sessionToken value', sessionToken);
		}
		state.sessionIdCookie = mySessionToken;

		const expires = mySessionToken === false ? -1 : undefined; // if sessionToken is false, set expire to the past, so it gets removed
		if (process.client && process.env.loginCookie) {
			document.cookie = genCookie(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				process.env.loginCookie,
				mySessionToken,
				expires,
				window.location.host
			);
		} /* else if (process.server && res) {
			if (res && typeof res.header === 'function') {
				res.header('Set-Cookie', genCookie(process.env.loginCookie, mySessionToken, expires));
			} else if (res && typeof res.setHeader === 'function') {
				// pass along to browser
				res.setHeader('Set-Cookie', genCookie(process.env.loginCookie, mySessionToken, expires));
			} else {
				console.error('cannot set header via SSR!');
			}
		} */
	},
	loggedIn(state): void {
		state.loggedIn = true;
	},
	setReAuthData(state, { reAuthTokens }): void {
		state.reAuthData = reAuthTokens; // {id, token}

		if (process.client) {
			document.cookie = genCookie('pwaReAuth', JSON.stringify(reAuthTokens), 90);
			if (lsTest()) {
				localStorage.setItem('reAuthData', JSON.stringify(reAuthTokens));
			}
		} /* else if (process.server) {
			if (res && typeof res.header === 'function') {
				res.header('Set-Cookie', genCookie('pwaReAuth', JSON.stringify(reAuthTokens), 90));
			} else if (res && typeof res.setHeader === 'function') {
				// pass along to browser
				res.setHeader('Set-Cookie', genCookie('pwaReAuth', JSON.stringify(reAuthTokens), 90));
			} else {
				// pass along to browser
				console.error('cannot set header via SSR!');
			}
		} */
	},
	resetState(currentState): void {
		Object.assign(currentState, defaultState());
	}
};

export default mutations;
