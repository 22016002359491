import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { IFaqState } from '~/store/faq/state';

export const mutations: MutationTree<IFaqState> = {
	updateFaqSectionList(state, faqSectionList) {
		Vue.set(state, 'faqSectionList', faqSectionList);
	}
};

export default mutations;
