import Vue from 'vue';
import type { MutationTree } from 'vuex';
import type { IUserJobFilterState } from './state';
import { defaultState } from './state';

export const mutations: MutationTree<IUserJobFilterState> = {
	setPossibleResults(state, possibleResults) {
		Vue.set(state, 'possibleResults', possibleResults);
	},
	resetState(currentState): void {
		Object.assign(currentState, defaultState());
	}
};

export default mutations;
