
import { Component, Prop, Watch, Provide, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { insidePages } from '@hokify/shared-components/lib/plugins/insidepage/insidePage';
import { EventBus } from '@hokify/shared-components/lib/eventbus';
import { getCookie } from '@hokify/shared-components/lib/helpers/cookie';
import CustomHtmlTag from '~/components/custom-html-tag';
import { setTesterGroupCookie } from '~/helpers/cookie';
import type {
	IAPIFeatureAdvertisementSimple,
	IAPIFeatureAdvertisementExtended,
	IAPILoginUser
} from '@hokify/common';

const loginStore = namespace('login');
const featureStore = namespace('featureadvertisement');
const userStore = namespace('user/profile');

@Component({
	components: {
		CustomHtmlTag,
		VDialog: () => import('@hokify/shared-components/lib/plugins/hok-modal/Dialog.vue'),
		InsidePageRoot: () =>
			import('@hokify/shared-components/lib/plugins/insidepage/InsidePageRoot.vue'),
		FeatureAdvertisement: () =>
			import('@hokify/shared-components/lib/components/FeatureAdvertisement.vue')
	},
	head(this: WebsiteMain) {
		let lang = 'de';
		switch (this.$store.state.topLevelDomain) {
			case 'at':
				lang = 'de-AT';
				break;
			case 'de':
				lang = 'de-DE';
				break;
			case 'ch':
				lang = 'de-CH';
				break;
			default:
		}

		return {
			bodyAttrs: {
				class: this.headers && this.headers.join(' ')
			},
			htmlAttrs: {
				lang
			}
		};
	}
})
export default class WebsiteMain extends Vue {
	@loginStore.State('loggedIn') loggedIn;

	@featureStore.State('features') features;

	@userStore.State('obj') user?: IAPILoginUser;

	@Prop({ type: Boolean, default: true }) readonly standalone!: boolean;

	@Prop({ type: String, default: 'none' }) readonly styling!: string;

	headers: string[] = [];

	hasInteracted = false;

	cref = '';

	// @todo: set cref or ure by page or something? globally somehopw..vuex?
	uref = '';

	obj: any = null;

	blockBodyScrolling = false;

	get tabs() {
		return this.page.isVisibleTab;
	}

	get activeInsidePages() {
		return !!(insidePages.pages && insidePages.pages.length);
	}

	get insidePages() {
		return insidePages.pages;
	}

	get currentRoute(): string {
		return this.$route.path;
	}

	get featuresOnRoute(): IAPIFeatureAdvertisementSimple | IAPIFeatureAdvertisementExtended {
		return this.features.find(
			feat =>
				feat.mainUrl === this.currentRoute &&
				(!feat.testerGroup || feat.testerGroup === this.user?.testerGroup)
		);
	}

	get companyMode(): boolean {
		return this.$route.path.includes('/business');
	}

	@Watch('activeInsidePages') onInsidePagesClosed(curOpen) {
		if (!curOpen) {
			EventBus().$emit('page-close-observed');
		}
	}

	@Watch('tabs') onTabChange() {
		if (this.insidePages.length === 0) {
			// if no insidepage, we need to check it by our own, otherwise the insidepage triggers this anyway
			this.computeCSSHeaders();
		}
	}

	@Watch('$route') onRouteChange() {
		setTimeout(() => {
			if (this.featuresOnRoute) {
				this.$modal.show('feature-advertisement');
			}
		}, 1500);
	}

	created() {
		this.computeCSSHeaders();
	}

	async mounted() {
		const options = {
			once: true,
			passive: true,
			capture: false
		};
		window.addEventListener('focus', this.setHasInteracted, options);
		window.addEventListener('keydown', this.setHasInteracted, options);
		window.addEventListener('mousemove', this.setHasInteracted, options);
		window.addEventListener('mousewheel', this.setHasInteracted, options);
		window.addEventListener('touchstart', this.setHasInteracted, options);

		if (this.features.length === 0) {
			try {
				await this.$store.dispatch('featureadvertisement/loadFeatureAdvertisement');
			} catch (err: any) {
				this.$errorHandler.call(this, err);
			}
		}
	}

	removeEventListeners() {
		window.removeEventListener('focus', this.setHasInteracted, { capture: false });
		window.removeEventListener('keydown', this.setHasInteracted, { capture: false });
		window.removeEventListener('mousemove', this.setHasInteracted, { capture: false });
		window.removeEventListener('mousewheel', this.setHasInteracted, { capture: false });
		window.removeEventListener('touchstart', this.setHasInteracted, { capture: false });
	}

	get testerGroupCookieCheck(): string | false | undefined {
		const cookies = process.client && window.document.cookie;
		return cookies && getCookie('testerGroup', cookies);
	}

	setHasInteracted(_event) {
		this.removeEventListeners();
		this.hasInteracted = true;
		EventBus().$emit('clientHasInteracted');
		if (!this.testerGroupCookieCheck && !this.loggedIn) {
			setTesterGroupCookie();
		}
	}

	@Provide() page = { isVisibleTab: [] };

	computeCSSHeaders(additionalHeaders?: string[]) {
		const headers: string[] = [];
		headers.push(this.$isMobile.any ? 'mobile' : 'desktop');

		if (additionalHeaders) {
			additionalHeaders.forEach(h => headers.push(h));
		}

		if (this.blockBodyScrolling) {
			headers.push('overflow-hidden');
		}

		this.headers = headers;
	}

	disableScrolling() {
		this.blockBodyScrolling = true;
		this.computeCSSHeaders();
	}

	enableScrolling() {
		this.blockBodyScrolling = false;
		this.computeCSSHeaders();
	}
}
