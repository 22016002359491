import type { IAPICompany } from '@hokify/common';

export interface ICompanyState {
	topCompanies: IAPICompany[];
	otherCompanies: IAPICompany[];
}

export const defaultState = (): ICompanyState => ({
	topCompanies: [],
	otherCompanies: []
});

export default defaultState;
