import type { ActionTree } from 'vuex';
import { calculateHash } from '@hokify/shared-components/lib/helpers/utility';
import type { IRootState } from '~/store';
import type { ICustomerLPState } from '~/store/user/landingpage/state';

export const actions: ActionTree<ICustomerLPState, IRootState> = {
	async loadLandingPageData({ commit }) {
		const landingPageDate = await this.$axios.$get(`/website-api/general/user-landing-page`);
		commit('updateLandingPageData', landingPageDate);
	},

	async sendReferralInvite(_, contactInfo: string) {
		const result = await this.$axios.$post(`/website-api/general/referral-invite`, {
			contact: contactInfo,
			referralCode: calculateHash(contactInfo)
		});
		return result;
	}
};

export default actions;
