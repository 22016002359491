import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { IFeatureFlagState } from './state';

function getLocalStorageOverwrite(name: string): boolean | null {
	if (!lsTest()) {
		return null;
	}

	const localFeatureFlag = localStorage.getItem(name);

	return localFeatureFlag !== null ? localFeatureFlag === 'true' : null;
}

export const actions: ActionTree<IFeatureFlagState, IRootState> = {
	async getFeatureFlagEnabledByName({ state, commit }, name: string) {
		const localStorageOverwrite = getLocalStorageOverwrite(name);
		if (localStorageOverwrite !== null) {
			return localStorageOverwrite;
		}

		if (state.featureFlags[name] !== undefined) {
			return state.featureFlags[name];
		}

		const result = await this.$axios.$get(`/website-api/feature-flags/${name}`);
		commit('addFeatureFlag', { name, enabled: result });

		return result;
	}
};

export default actions;
