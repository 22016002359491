import Vue from 'vue';
import type { MutationTree } from 'vuex';
import type { IMatchesState } from './state';
import { defaultState } from './state';

export const mutations: MutationTree<IMatchesState> = {
	// these matches are NOT fullyLoaded
	saveListOfMatches(state, matches) {
		matches.forEach(matchObj => {
			if (!matchObj) {
				console.warn('saveListOfMatches with empty matchObj', matchObj);
				return;
			}

			const notFullyLoadedMatch = {
				match: matchObj,
				fullyLoaded: false
			};

			// if match is already in store, update it
			const found = state.all.some((m, i) => {
				if (m.match._id === matchObj._id) {
					Vue.set(state.all, i, notFullyLoadedMatch);
					return true;
				}
				return false;
			});
			// if match is not in store, add it
			if (!found) {
				state.all.push(notFullyLoadedMatch);
			}
		});
	},

	// these matches are fullyLoaded
	saveMatch(state, matchObj) {
		if (!matchObj) {
			console.warn('saveMatch with empty matchObj', matchObj);
			return;
		}

		const fullyLoadedMatchObj = {
			match: matchObj,
			fullyLoaded: true
		};

		// if match is already in store, update it
		const found = state.all.some((m, i) => {
			if (m.match._id === matchObj._id) {
				Vue.set(state.all, i, fullyLoadedMatchObj);
				return true;
			}
			return false;
		});
		// if match is not in store, add it
		if (!found) {
			// unshift new match instead of pushing, to be sure it is on top
			state.all.unshift(fullyLoadedMatchObj);
		}
	},

	deleteMatch(state, match) {
		state.all.some((m, index) => {
			if (m.match._id === match._id) {
				state.all.splice(index, 1);
				return true;
			}
			return false;
		});
	},

	resetState(state) {
		Object.assign(state, defaultState());
	}
};

export default mutations;
