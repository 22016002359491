
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { createObserver, resolvableComponentFactory } from '../helpers/lazy';

@Component({
	name: 'HokIcon'
})
export default class HokIcon extends Vue {
	@Prop({ type: String, required: true }) readonly icon!: string;

	@Prop({ type: Boolean, default: false }) readonly pointer!: boolean;

	@Prop({
		type: Number,
		default: 6,
		validator: (value: number) =>
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 20, 24, 32, 40, 48, 56, 64].includes(value)
	})
	readonly size!: number;

	@Prop({
		type: String,
		default: 'default',
		validator: (value: string) =>
			[
				'blue',
				'yellow',
				'main',
				'purple',
				'main-business',
				'grey',
				'white',
				'blue-grey',
				'text',
				'grey-light',
				'grey-medium',
				'coral',
				'default'
			].includes(value)
	})
	readonly color!: string;

	@Prop({
		type: String,
		default: 'align-middle',
		validator: (value: string) =>
			[
				'align-middle',
				'align-baseline',
				'align-top',
				'align-bottom',
				'align-text-top',
				'align-text-bottom'
			].includes(value)
	})
	readonly verticalAlign!: string;

	component!: object | undefined;

	data() {
		return {
			component: undefined
		};
	}

	renderedIcon = '';

	hashCode(s) {
		if (!s) {
			return '';
		}
		let h = 0;
		for (let i = 0; i < s.length; i += 1) {
			// eslint-disable-next-line no-bitwise
			h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
		}

		return h.toString(16);
	}

	get iconKey() {
		return (
			(this.renderedIcon || this.icon) + this.size + this.color + this.verticalAlign + this.pointer
		);
	}

	@Watch('icon') onIconChanged() {
		this.component = undefined;
	}

	@Watch('styling') onStylingChanged() {
		this.component = undefined;
	}

	@Watch('color') onColorChange() {
		this.component = undefined;
	}

	initial = true;

	render(createElement) {
		const { $el }: { $el?: Element & { dataset?: { icon?: string } } } = this;
		const tag = $el?.tagName; // must be svg
		const renderedIcon = $el?.dataset?.icon || $el?.getAttribute?.('data-icon'); // currently rendered icon
		if (
			$el?.nodeType === 1 &&
			tag?.toUpperCase() === 'SVG' &&
			renderedIcon === this.icon &&
			this.initial &&
			$el?.childElementCount === 0 // only when we have an element, and child element count is 0
		) {
			// console.log('not hydrating icon', this.icon);
			// this component is already rendered on serverside, just reuse it
			this.initial = false; // we can only hydrate once ;)
			return this.myRender(createElement, true);
		}

		// console.log('loading icon', this.icon);
		return this.myRender(createElement);
	}

	get myclass() {
		const myclass: string[] = [
			process.server && this.$vnode.data?.class, // for ssr
			process.server && this.$vnode.data?.staticClass, // for ssr
			// ctx.data.class,
			// ctx.data.staticClass,
			'icon',
			this.icon.replace(/-\d$/, ''),
			'overflow-visible'
		];

		if (this.pointer) {
			myclass.push('cursor-pointer');
		}

		myclass.push(this.verticalAlign);

		switch (this.size) {
			case 0:
				myclass.push('w-0 h-0');
				break;
			case 1:
				myclass.push('w-1 h-1');
				break;
			case 2:
				myclass.push('w-2 h-2');
				break;
			case 3:
				myclass.push('w-3 h-3');
				break;
			case 4:
				myclass.push('w-4 h-4');
				break;
			case 5:
				myclass.push('w-5 h-5');
				break;
			case 6:
				myclass.push('w-6 h-6');
				break;
			case 7:
				myclass.push('w-7 h-7');
				break;
			case 8:
				myclass.push('w-8 h-8');
				break;
			case 10:
				myclass.push('w-10 h-10');
				break;
			case 12:
				myclass.push('w-12 h-12');
				break;
			case 14:
				myclass.push('w-14 h-14');
				break;
			case 16:
				myclass.push('w-16 h-16');
				break;
			case 20:
				myclass.push('w-20 h-20');
				break;
			case 24:
				myclass.push('w-24 h-24');
				break;
			case 32:
				myclass.push('w-32 h-32');
				break;
			case 40:
				myclass.push('w-40 h-40');
				break;
			case 48:
				myclass.push('w-48 h-48');
				break;
			case 56:
				myclass.push('w-56 h-56');
				break;
			case 64:
				myclass.push('w-64 h-64');
				break;
			default:
			// nothing!
		}

		switch (this.color) {
			case 'blue':
				myclass.push('text-color-blue');
				break;
			case 'yellow':
				myclass.push('text-color-yellow');
				break;
			case 'main':
				myclass.push('text-color-main');
				break;
			case 'main-business':
				myclass.push('text-color-main-business');
				break;
			case 'purple':
				myclass.push('text-color-purple');
				break;
			case 'grey':
				myclass.push('text-color-grey');
				break;
			case 'white':
				myclass.push('text-color-white');
				break;
			case 'blue-grey':
				myclass.push('text-color-blue-grey');
				break;
			case 'text':
				myclass.push('text-color-text');
				break;
			case 'grey-light':
				myclass.push('text-color-grey-light');
				break;
			case 'grey-medium':
				myclass.push('text-color-grey-medium');
				break;
			case 'coral':
				myclass.push('text-color-coral');
				break;
			default:
			// nothing!
		}

		return myclass;
	}

	myRender(createElement, domExists?) {
		if (!this.component) {
			const data: any = {
				class: this.myclass,
				directives: this.$vnode.data?.directives,
				// style: [this.$vnode.data?.style, this.$vnode.data?.staticStyle],
				key: `icon-${this.hashCode(this.iconKey)}`,
				attrs: {
					'data-icon': this.icon
				},
				on: { click: ev => this.$emit('click', ev) }
			};

			const loading = {
				render: h => h('svg', data)
			};

			if (domExists) {
				this.component = () => ({
					component: new Promise(_resolve => {}), // never resolving promise, prevents hokicon from client hydration
					delay: 0,
					loading
				});
			} else {
				// get icon data
				const observer = createObserver({});

				const resolvableComponent = resolvableComponentFactory(() =>
					import(`../../assets/svgs/icons/${this.icon}.svg?inline`) // use  import instead of require - to load async
						.then(async (svg: any) => ({
							render: h => h(svg.default || svg, data)
						}))
				);

				// eslint-disable-next-line no-underscore-dangle
				if (process.server) {
					// on server we resolve immediately, as we do not have an observer anyway ;)
					(resolvableComponent as any)._resolve();
				}

				this.component = () => ({
					component: resolvableComponent,
					delay: process.server ? 200 : 0,
					loading: {
						...loading,
						mounted: () => {
							// If Intersection Observer API is not supported, hydrate immediately.
							if (!observer) {
								// eslint-disable-next-line no-underscore-dangle
								(resolvableComponent as any)._resolve();
								return;
							}

							// eslint-disable-next-line no-underscore-dangle
							(this.$el as any).hydrate = (resolvableComponent as any)._resolve;
							const cleanup = () => observer.unobserve(this.$el);
							resolvableComponent.then(cleanup);
							observer.observe(this.$el);
						}
					}
				});
			}
		}

		return createElement(this.component);
	}
}
