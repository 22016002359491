import type { IAPIWebinar, IAPIPodcast, IAPIPodcastList } from '@hokify/common';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import { IB2BContentState } from '~/store/b2b-content/state';
import { generateQueryString } from '~/helpers/vuex-store';

export const actions: ActionTree<IB2BContentState, IRootState> = {
	async loadUpcomingWebinars(
		{ commit },
		params: { page?: number; limit?: number } = {}
	): Promise<number> {
		const result = await this.$axios.$get(
			`/website-api/webinars/webinars/upcoming?${generateQueryString(params)}`
		);

		commit('updateUpcomingWebinarTeasers', result.webinars);

		return result.webinars?.length || 0;
	},
	async loadPastWebinars(
		{ commit },
		params: { page?: number; limit?: number } = {}
	): Promise<number> {
		const result = await this.$axios.$get(
			`/website-api/webinars/webinars/past?${generateQueryString(params)}`
		);

		commit('updatePastWebinarTeasers', result.webinars);

		return result.webinars?.length || 0;
	},
	async loadWebinarDetailPageData({ commit }, alias: string): Promise<Record<string, IAPIWebinar>> {
		const result = await this.$axios.$get(
			`/website-api/webinars/webinar/${encodeURIComponent(alias)}`
		);
		commit('upsertWebinar', result.webinar);

		return result;
	},

	async registerForWebinar(_, alias: string): Promise<{ success: boolean }> {
		const result = await this.$axios.$post(
			`/website-api/webinars/webinar/${encodeURIComponent(alias)}/register`
		);
		return result;
	},

	async getWebinarVideoLink(_, alias: string): Promise<{ url: string }> {
		const result = await this.$axios.$get(
			`/website-api/webinars/webinar/${encodeURIComponent(alias)}/video-link`
		);
		return result;
	},

	async loadPodcasts(
		{ commit },
		params: { page?: number; limit?: number } = {}
	): Promise<IAPIPodcastList> {
		const result = await this.$axios.$get(`/website-api/podcasts?${generateQueryString(params)}`);
		commit('updatePodcastTeasers', result.podcasts);

		return result;
	},
	async loadPodcastDetailPageData({ commit }, alias: string): Promise<Record<string, IAPIPodcast>> {
		const result = await this.$axios.$get(
			`/website-api/podcasts/podcast/${encodeURIComponent(alias)}`
		);
		commit('upsertPodcast', result.podcast);

		return result;
	},
	async getPodcastAudioLink(_, alias: string): Promise<{ url: string }> {
		const result = await this.$axios.$get(
			`/website-api/podcasts/podcast/${encodeURIComponent(alias)}/audio-link`
		);
		return result;
	}
};

export default actions;
