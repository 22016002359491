import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;

export const actions: ActionTree<IRootState, IRootState> = {
	getPossibleFilter({ commit }, payload) {
		if (!payload) {
			payload = {};
		}

		return this.$axios
			.$post('/app-api/jobseeker/search/possible-filters', payload, { baseURL })
			.then(res => {
				const { possibleResults } = res;
				commit('setPossibleResults', possibleResults);
				return res;
			});
	}
};

export default actions;
