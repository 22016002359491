
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Autocomplete from '@hokify/shared-components/lib/components/Autocomplete.vue';
import LocationAutocomplete from '@hokify/shared-components/lib/components/LocationAutocomplete.vue';
import AutocompleteExperimental from '@hokify/shared-components/lib/components/Autocomplete/AutocompleteExperimental.vue';
import LocationAutocompleteExperimental from '@hokify/shared-components/lib/components/LocationAutocomplete/LocationAutocompleteExperimental.vue';
import WebsiteHeader from '~/components/website/header/WebsiteHeader.vue';
import WebsiteMain from '~/components/website/WebsiteMain.vue';

const locationStore = namespace('location');
const valuesStore = namespace('values');

@Component({
	name: 'ErrorLayout',
	components: {
		Autocomplete,
		LocationAutocomplete,
		AutocompleteExperimental,
		LocationAutocompleteExperimental,
		WebsiteHeader,
		WebsiteFooter: () => import('~/components/website/footer/WebsiteFooter.vue'),
		WebsiteMain
	},
	head() {
		let lang = 'de';
		switch (this.$store.state.topLevelDomain) {
			case 'at':
				lang = 'de-AT';
				break;
			case 'de':
				lang = 'de-DE';
				break;
			case 'ch':
				lang = 'de-CH';
				break;
			default:
		}
		return {
			bodyAttrs: {
				class: this.$isMobile.any ? 'mobile' : 'desktop'
			},
			htmlAttrs: {
				lang
			}
		};
	}
})
export default class ErrorLayout extends Vue {
	@Ref('branchAutocomplete') readonly branchAutocomplete;

	@Ref('locationAutocomplete') readonly locationAutocomplete;

	@Prop({}) readonly error!: any;

	@locationStore.Action('resolveGeoCoords') resolveGeoCoords;

	@valuesStore.Action('ontologyKeywordSuggestion') ontologyKeywordSuggestion;

	isCompanyPage = this.$route.path.startsWith('/business');

	branch = '';

	city = '';

	showMain = true;

	mounted() {
		this.$trackWebsiteEvent('errorpage_view', {});
	}

	get message() {
		let title = '';
		if (this.error.isAxiosError) {
			title = 'Backend Request failed: ';
		}

		if (this.error.statusCode >= 500) {
			title = 'Serverfehler: ';
		}

		return (
			title +
			((this.error?.statusCode >= 500 &&
				(this.error.message || 'Serverfehler, bitte versuch es noch einmal')) ||
				(this.error.response?.data?.name &&
					`${this.error.response?.data?.name} ${this.error.response?.data?.message}`) ||
				this.error.message ||
				`Error`)
		);
	}

	get internalError(): { heading: string; message: string; code: string } {
		if (this.error.isArticlePage) {
			return {
				heading: 'Artikel nicht gefunden',
				message: 'Der angegebene Artikel existiert nicht.',
				code: '(Fehler 404)'
			};
		}
		if (this.error.statusCode === 410) {
			return {
				heading: 'Seite existiert nicht',
				message: `${this.isCompanyPage ? 'Ihre ' : 'Deine '} gesuchte Seite wurde entfernt.`,
				code: '(Fehler 410)'
			};
		}
		if (this.error.statusCode === 404) {
			return {
				heading: 'Seite nicht gefunden',
				message: `${this.isCompanyPage ? 'Überprüfen Sie Ihre ' : 'Überprüfe deine '} Eingabe auf Richtigkeit.`,
				code: '(Fehler 404)'
			};
		}
		return {
			heading: 'Seite funktioniert nicht',
			message: `${this.isCompanyPage ? 'Bitte versuchen Sie es später nochmal oder kontaktiere Sie uns.' : 'Bitte versuche es später nochmal oder kontaktiere uns.'}`,
			code: `(Fehler ${this.error.statusCode})`
		};
	}

	updateBranch(
		searchTerm: string,
		_event,
		_parameters?: { term: string; type: string; data?: { alias?: string } }
	) {
		this.branch = searchTerm;
	}

	selectBranch(
		_searchTerm: string,
		parameters?: { term: string; type: string; data?: { alias?: string } }
	) {
		if (parameters?.type === 'company' && parameters?.data?.alias) {
			this.$router.push({ path: `/c/${parameters.data.alias}` });
		}
	}

	mapSuggestionType(type: 'company' | 'keyword') {
		return type === 'company' ? 'Firma' : 'Job';
	}

	async handleJobSearch() {
		this.$router.push({
			path: '/jobs/search',
			query: {
				branch: this.branch,
				city: this.city
			}
		});
	}

	async openSearchbarPage() {
		this.$nuxt.$loading.start();
		return import('~/components/insidepages/SearchbarPage.vue')
			.then(async searchbarComponent => {
				this.showMain = false;
				await this.$page.push(
					searchbarComponent,
					{},
					{
						name: 'searchbar-page',
						mode: 'modal',
						done: () => {
							this.showMain = true;
						}
					}
				);
				this.$nuxt.$loading.finish();
			})
			.catch(err => this.$errorHandler.call(this, err));
	}
}
