import Vue from 'vue';
import SharedComponentsPlugin from '@hokify/shared-components';

export default (ctx, inject) => {
	Vue.use(SharedComponentsPlugin, {
		googleApiKey: process.env.googleApiKey,
		ctx,
		inject
	});
};
