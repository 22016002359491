import type { ActionTree } from 'vuex';
import type {
	APIObjectType,
	APITypeObjectId,
	IAPIJobAlarmSubscription,
	IAPIJobFilterBasicRequest
} from '@hokify/common';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async getJobalarmSubscriptions(_, jobAlarmId: string) {
		const result = await this.$axios.$get(`/website-api/jobalarm/${jobAlarmId}`);
		return result;
	},

	async getSubscription(
		_,
		{ jobalarmId, subscriptionId }: { jobalarmId: string; subscriptionId: string }
	): Promise<IAPIJobAlarmSubscription> {
		const result = await this.$axios.$get(
			`/website-api/jobalarm/${jobalarmId}/${subscriptionId}/search`
		);
		return result;
	},

	async deleteJobalarm(_, { jobalarmId }: { jobalarmId: string }) {
		const result = await this.$axios.$delete(`/website-api/jobalarm/${jobalarmId}`);
		return result;
	},

	async deleteSubscription(
		_,
		{ jobalarmId, subscriptionId }: { jobalarmId: string; subscriptionId: string }
	) {
		const result = await this.$axios.$delete(
			`/website-api/jobalarm/${jobalarmId}/${subscriptionId}`
		);
		return result;
	},

	async createJobAlarm(_, payload) {
		await this.$axios.$put('/website-api/jobalarm/create', payload);
	},

	async verifyEmail(_, payload) {
		const result = await this.$axios.$post('/website-api/jobalarm/verify', payload);
		return result;
	},

	async setInterval(_, { jobalarmId, interval }: { jobalarmId: string; interval: number }) {
		const result = await this.$axios.$post(`/website-api/jobalarm/${jobalarmId}`, { interval });
		return result;
	},

	async editJobAlarm(
		_,
		{
			filter,
			jobAlarmId,
			subscriptionId
		}: {
			filter: IAPIJobFilterBasicRequest;
			jobAlarmId: APITypeObjectId<APIObjectType.JobAlarm>;
			subscriptionId: APITypeObjectId<APIObjectType.JobAlarmSubscription>;
		}
	) {
		try {
			const res = await this.$axios.$post(
				`/website-api/jobalarm/edit/${jobAlarmId}/${subscriptionId}`,
				filter
			);
			return res.subscriptions;
		} catch (error: any) {
			console.error('editJobAlarm failed', error);
			throw error.response?.data || error.response || error;
		}
	}
};

export default actions;
