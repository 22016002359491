import type { IAPIImage } from '@hokify/common';
import type { IAPIArticleTeaser, IAPIArticle } from '@hokify/magazine-interface';

export interface IMagazineState {
	articles: { [key: string]: IAPIArticle };
	articleTeasers: { [key: string]: IAPIArticleTeaser[] };
	pressLinks: IAPIPagePressLinks[];
}

// TODO: get IAPIPagePressLinks from @hokify/common', not available yet
export interface IAPIPagePressLinks {
	title: string;
	link: string;
	date: Date;
	logo: IAPIImage;
}

export const defaultState = (): IMagazineState => ({
	articles: {},
	articleTeasers: {},
	pressLinks: []
});

export default defaultState;
