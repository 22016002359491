import type { IAPIMatchForJobSeekerListEntry, IAPIMatchForJobSeeker } from '@hokify/common';

export interface IMatchesState {
	all: {
		match: IAPIMatchForJobSeekerListEntry | IAPIMatchForJobSeeker;
		fullyLoaded: boolean;
	}[];
}

export const defaultState = (): IMatchesState => ({
	all: []
});

export default defaultState;
