import { APIObjectType, APITypeObjectId } from '@hokify/common';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IValuesState } from './state';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;

function compareFct(key: string): (a: object, b: object) => number {
	return function compareFctInner(a, b): number {
		if (a[key] < b[key]) {
			return -1;
		}
		if (a[key] > b[key]) {
			return 1;
		}
		return 0;
	};
}

let cancelToken;

export const actions: ActionTree<IValuesState, IRootState> = {
	async companyLocationSuggestions(
		{ dispatch },
		{ term, companyId }: { term: string; companyId: APITypeObjectId<APIObjectType.Company> }
	) {
		return dispatch('getSuggestion', {
			route: 'companies/location-suggestions',
			query: 'term',
			term,
			companyId
		});
	},
	async organizationSuggestion({ dispatch }, term: string) {
		return dispatch('getSuggestion', {
			route: 'suggestions/organizations',
			query: 'searchTerm',
			term
		});
	},
	// returns ONLY Job suggestions
	async ontologySuggestion({ dispatch }, term: string) {
		return dispatch('getSuggestion', {
			route: 'jobs/keywords',
			query: 'term',
			term
		});
	},
	// returns Job AND Company suggestions
	async ontologyKeywordSuggestion({ dispatch }, term: string) {
		return dispatch('getSuggestion', {
			route: 'jobs/keyword-suggestions',
			query: 'term',
			term
		});
	},
	async getSuggestion(
		_,
		{
			route,
			query,
			term,
			companyId
		}: {
			route: string;
			query: string;
			term: string;
			companyId?: APITypeObjectId<APIObjectType.Company>;
		}
	) {
		if (cancelToken) {
			cancelToken();
			cancelToken = null;
		}

		const { CancelToken } = this.$axios;

		return this.$axios
			.get(
				`/website-api/${route}
				${companyId ? `/${companyId.toString()}` : ''}
				${term.toString() ? `?${query}=${term.toString()}` : ''}`,
				{
					cancelToken: new CancelToken(c => {
						cancelToken = c;
					})
				}
			)
			.then(res =>
				res.data.filter(obj => {
					if (obj && obj.value) {
						return obj.value.toLowerCase().includes(term.toLowerCase());
					}
					return true;
				})
			);
	},
	async getJobFields({ dispatch }) {
		return dispatch('getFromCacheViaAppAPI', {
			route: '/app-api/utils/jobfields',
			sortMode: 'jobfields'
		});
	},
	async getFromCacheViaAppAPI({ state, commit }, payload) {
		const { route, cacheid } = payload;
		const cacheKey = cacheid ? `${route}|${cacheid}` : route;
		if (state.cache && state.cache[cacheKey]) {
			return state.cache[cacheKey];
		}

		const data = await this.$axios.$get(route, { baseURL });

		if (payload.sortMode) {
			switch (payload.sortMode) {
				case 'jobfields':
					data.sort(compareFct('name'));
					data.forEach(parent => {
						parent.children.sort(compareFct('name'));
					});
					break;
				default:
					throw new Error('unsupporetd sortBy');
			}
		}

		commit('cache', {
			cacheKey,
			data
		});
		return data;
	}
};

export default actions;
