import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IFeedbackPayload, IUserFeedbackPayload } from '~/store/types';
import type { IComplaint } from '@hokify/common';

export const actions: ActionTree<IRootState, IRootState> = {
	// Business Feedback
	async loadFeedbackData(_, { job, code }: { job: string; code: string }) {
		const result = await this.$axios.$get(`/website-api/companies/feedback/${job}/${code}`);
		return result;
	},
	async saveFeedback(_, payload: IFeedbackPayload) {
		const result = await this.$axios.$post('/website-api/companies/save-feedback', payload);
		return result;
	},

	// User Feedback
	async loadUserFeedbackData() {
		const result = await this.$axios.$get('/website-api/general/feedback');
		return result;
	},
	async saveUserFeedback(_, payload: IUserFeedbackPayload[]) {
		const result = await this.$axios.$post('/website-api/general/save-feedback', payload);
		return result;
	},
	// user complaint
	async saveUserComplaint(_, payload: IComplaint) {
		return this.$axios.$post('/website-api/general/complaint', payload);
	}
};

export default actions;
