var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative",attrs:{"tabindex":"0"},on:{"blur":function($event){_vm.active = false}}},[_c('div',{staticClass:"select-element",class:{
			'active-state': _vm.active,
			disabled: _vm.disabled,
			filled: !!_vm.internalValue
		}},[(_vm.internalValue)?_c('span',{staticClass:"description"},[_vm._v("\n\t\t\t"+_vm._s(_vm.label || _vm.chooseText || 'bitte auswählen...')+"\n\t\t\t"),(_vm.required)?_c('span',{class:{ required: !_vm.internalValue }},[_vm._v("*")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('label',{staticClass:"p-3 flex items-center",class:_vm.disabled ? 'cursor-not-allowed' : 'cursor-pointer',on:{"click":function($event){!_vm.active ? _vm.getValues() : _vm.resetVal()}}},[(_vm.internalValue && _vm.hasValue(_vm.internalValue) && _vm.internalValue.value)?_c('span',{key:_vm.internalValue.value,staticClass:"flex-auto input"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.internalValue.value)+"\n\t\t\t")]):_c('span',{staticClass:"flex-auto input"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.chooseText || 'bitte auswählen...')+"\n\t\t\t\t"),(!_vm.internalValue && _vm.required)?_c('span',{class:{ required: !_vm.internalValue }},[_vm._v("*")]):_vm._e()]),_vm._v(" "),(!_vm.active)?_c('HokIcon',{attrs:{"icon":"icon-arrow-down","size":4,"color":_vm.disabled ? 'grey-light' : 'default'}}):_vm._e(),_vm._v(" "),(_vm.active)?_c('HokIcon',{attrs:{"icon":"icon-arrow-up","size":4,"color":"main","pointer":""}}):_vm._e()],1)]),_vm._v(" "),(_vm.active)?_c('ul',{staticClass:"shadow-lg rounded-b-lg overflow-hidden bg-color-white w-full z-10 absolute overflow-y-auto select-wrapper",class:{ 'active-state': !!_vm.active, 'h-[235px]': _vm.limitDropdownHeight }},[(_vm.loading)?_c('Spinner',{attrs:{"fixed":false}}):_vm._e(),_vm._v(" "),_vm._l((_vm.values),function(option,index){return _c('li',{key:option.id || index,staticClass:"p-3 hover:bg-color-grey-lightest border-color-grey-lightest border-b cursor-pointer",class:{
				'bg-color-grey-lightest':
					option.value === (_vm.internalValue && _vm.hasValue(_vm.internalValue) && _vm.internalValue.value)
			},on:{"mousedown":function($event){$event.preventDefault();},"click":function($event){return _vm.setCurrentVal($event, option)}}},[_vm._t("item",function(){return [_vm._v("\n\t\t\t\t"+_vm._s(option.value)+"\n\t\t\t")]},{"item":option,"value":_vm.internalValue})],2)})],2):_vm._e(),_vm._v(" "),(_vm.internalValue && _vm.hasId(_vm.internalValue) && _vm.internalValue.id)?_c('input',{attrs:{"type":"hidden","name":_vm.submitValue},domProps:{"value":_vm.internalValue.id}}):_vm._e(),_vm._v(" "),(_vm.error)?_c('ErrorBox',[_vm._v("\n\t\tDie Auswahl kann zurzeit nicht geladen werden. Bitte versuch es später noch einmal!\n\t\t"+_vm._s(_vm.error)+"\n\t")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }