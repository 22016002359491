
import { defineComponent } from 'vue';
import HokIcon from './HokIcon.vue';
import Spinner from './Spinner.vue';
import ErrorBox from './ErrorBox.vue';

export default defineComponent({
	name: 'HokSelect',
	components: { HokIcon, Spinner, ErrorBox },
	data() {
		const internalValue = (this.value && { value: this.value }) as
			| {
					id?: string | number;
					value: string;
					params?: any;
			  }
			| string
			| undefined;

		const values: {
			id?: string | number;
			value: string;
			params?: any;
		}[] = [];

		return {
			internalValue,
			loading: false,
			active: false,
			values,
			error: false
		};
	},
	async created() {
		if (this.value) {
			await this.loadValues();
			this.setVal(this.value);
		}
	},
	methods: {
		hasId(object: any): object is { id: string } {
			return typeof object === 'object' && 'id' in object;
		},
		hasValue(object: any): object is { value: string } {
			return typeof object === 'object' && 'value' in object;
		},
		resetVal(val?: string) {
			if (!val) {
				val = this.value;
			}
			this.internalValue =
				this.values?.find(v => v.value === val) ?? (this.value && { value: this.value });
			this.active = false;
		},
		setVal(val) {
			this.resetVal(val);
		},
		setCurrentVal($event, option) {
			this.internalValue = option;
			this.active = false;
			this.$emit('input', option, $event, { keyName: this.keyName });
		},
		async loadValues() {
			this.loading = true;
			try {
				this.values = await this.valuePromise();
				this.error = false;
			} catch (err: any) {
				console.error('err', err);
				this.error = err;
			}
			this.loading = false;
		},
		async getValues() {
			if (this.disabled) {
				return;
			}

			this.loading = true;
			this.active = true;
			try {
				this.values = await this.valuePromise();
				this.error = false;
			} catch (err: any) {
				console.error('err', err);
				this.active = false;
				this.error = err;
			}
			this.loading = false;
		},
		onValueChanged(newVal) {
			if (!newVal || newVal.length === 0) {
				this.resetVal();
			} else {
				this.setVal(newVal);
			}
		}
	},
	props: {
		keyName: { type: String, default: '' },
		value: { type: String, default: '' },
		chooseText: { type: String, default: '' },
		label: { type: String, default: '' },
		submitValue: { type: String, default: '' },
		required: { type: Boolean, default: false },
		limitDropdownHeight: { type: Boolean, default: false },
		valuePromise: { type: Function, required: true },
		disabled: { type: Boolean, default: false }
	},
	watch: {
		value: [
			{
				handler: 'onValueChanged'
			}
		]
	}
});
