import { Vue } from 'vue-property-decorator';

export default (_ctx, inject) => {
	const openLinkBrowser = async function openLinkBrowserFct(
		this: Vue,
		title: string,
		url: string,
		name?: string
	) {
		const LinkBrowser = await import('@hokify/shared-components/lib/components/LinkBrowser.vue');

		await this.$page.push(
			LinkBrowser,
			{
				url
			},
			{
				modalHeading: title,
				mode: 'modal',
				// useModal: true,
				name: name || 'link-browser'
			}
		);

		/*		return import('~/components/shared/LinkBrowser.vue')
			.then(LinkBrowser => {
				this.$nuxt.$loading.finish();

			})
			.catch(err => this.$errorHandler.call(this, err));
			*/
	};

	inject('openLink', openLinkBrowser);
};
