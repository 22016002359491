import { render, staticRenderFns } from "./plain-company.vue?vue&type=template&id=a873b3fe"
import script from "./plain-company.vue?vue&type=script&lang=ts"
export * from "./plain-company.vue?vue&type=script&lang=ts"
import style0 from "~/assets/styles/website_company.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports