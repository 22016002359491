import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IAPIMatchForJobSeekerListEntry } from '@hokify/common';
import type { IMatchesState } from './state';

const baseURL = process.server
	? process.env.APPAPI_HOST_SERVER ||
		process.env.APPAPI_HOST_BROWSER ||
		process.env.API_HOST_BROWSER
	: process.env.APPAPI_HOST_BROWSER || process.env.API_HOST_BROWSER;
let loadedSaved;
export const actions: ActionTree<IMatchesState, IRootState> = {
	async getSaved({ commit }, forceReload = false): Promise<IAPIMatchForJobSeekerListEntry[]> {
		// if API call was already made and result is available, return it
		if (loadedSaved && !forceReload) {
			return loadedSaved;
		}
		// if not, call API and save result into store
		const matches = await this.$axios.$get('/app-api/jobseeker/saved', { baseURL });
		if (matches && matches.length) {
			commit('saveListOfMatches', matches);
			loadedSaved = matches;
		}
		return loadedSaved;
	},

	async getUserMatchByRelation({ commit }, { relation }): Promise<string> {
		const matchResult = await this.$axios.$get(
			`/app-api/jobseeker/match/byRelation?relation=${relation.type}&relationId=${relation.obj._id}`,
			{ baseURL }
		);

		if (matchResult) {
			const matchFromApi = {
				match: matchResult,
				fullyLoaded: true
			};

			commit('saveMatch', matchFromApi);
		}
		return matchResult;
	},

	async deleteMatch({ commit }, { match }) {
		const response = await this.$axios.delete(`/app-api/jobseeker/match/${match._id}`, { baseURL });
		commit('deleteMatch', match);
		return response;
	},

	resetState: {
		root: true,
		handler({ commit }) {
			commit('resetState');
		}
	}
};

export default actions;
