import { MutationTree } from 'vuex';
import type {
	IAPIWebinar,
	IAPIWebinarTeaser,
	IAPIPodcastTeaser,
	IAPIPodcast
} from '@hokify/common';
import { updateStateArray } from '~/helpers/vuex-store';
import type { IB2BContentState } from './state';

export const mutations: MutationTree<IB2BContentState> = {
	updateUpcomingWebinarTeasers(state, teasers: IAPIWebinarTeaser[]) {
		updateStateArray(state, 'upcomingWebinarTeaser', teasers, 'alias');
	},
	updatePastWebinarTeasers(state, teasers: IAPIWebinarTeaser[]) {
		updateStateArray(state, 'pastWebinarTeaser', teasers, 'alias');
	},
	updatePodcastTeasers(state, teasers: IAPIPodcastTeaser[]) {
		updateStateArray(state, 'podcastTeaser', teasers, 'alias');
	},

	upsertWebinar(state, webinar: IAPIWebinar) {
		state.webinars[webinar.alias] = webinar;
	},
	upsertPodcast(state, podcast: IAPIPodcast) {
		state.podcasts[podcast.alias] = podcast;
	}
};

export default mutations;
