import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { getCookie } from '@hokify/shared-components/lib/helpers/cookie';
import { EventBus } from '@hokify/shared-components/lib/eventbus';

let clientResumedSession = false;
let clientLoginInitialized = false;

export default async ({ app, store, req, res, $sentry }) => {
	// LOGIN STUFF
	let cookies;
	if (process.server) {
		cookies = req.headers && req.headers.cookie;
	} else if (process.client) {
		cookies = window.document.cookie;
	}

	// check local cookies, if they are present and nothing is in the store yet, use them
	if (cookies && !store.state.login.sessionIdCookie) {
		const sessionToken = getCookie(process.env.loginCookie, cookies);

		if (sessionToken) {
			// setting session token
			if (process.env.development) {
				console.log('using sessionToken from cookie (login)', sessionToken, typeof sessionToken);
			}
			store.commit('login/sessionIdCookie', { sessionToken }, { root: true });
		}
	}

	const doLogin = async () => {
		// if we have a sessionIdCookie or we are on the client try login via session
		if (
			(store.state.login.sessionIdCookie || (!clientResumedSession && process.client)) &&
			!store.state.login.loggedIn /* && cookies && cookies.indexOf('connect.') !== -1 */
		) {
			if (process.client) {
				clientResumedSession = true;
			}
			if (process.env.development) {
				console.log(process.server ? 'SERVER' : 'CLIENT', 'resumeSession');
			}

			try {
				await store.dispatch('login/resumeSession');
			} catch (err) {
				// resumeSession may fail
				if (process.env.development) {
					console.log('session login failed', process.client && err);
				}
			}
		}

		// on server&client try login via reauth cookie
		if (!store.state.login.loggedIn && cookies && cookies.includes('pwaReAuth')) {
			if (process.env.development) {
				console.log(process.server ? 'SERVER' : 'CLIENT', 'cookieReAuth');
			}
			const parsedCookie = JSON.parse(getCookie('pwaReAuth', cookies) || '');
			if (parsedCookie) {
				const { tokenID, token } = parsedCookie;
				if (process.env.development) {
					console.log('tokenID', tokenID, token);
				}

				try {
					await store.dispatch('login/doLogin', {
						method: 'token-login',
						parameters: {
							apiUser: tokenID,
							token
						}
					});
				} catch (err) {
					// do Login may fail here
					if (process.env.development) {
						console.log('pwaReAuth login failed', process.client && err);
					}
				}
			}
		}

		// on client try a login via local storage
		if (
			process.client &&
			!store.state.login.loggedIn &&
			lsTest() &&
			localStorage.getItem('reAuthData')
		) {
			if (process.env.development) {
				console.log(process.server ? 'SERVER' : 'CLIENT', 'localStorageReAuth');
			}
			try {
				const parsedLocalStorage = JSON.parse(localStorage.getItem('reAuthData') || '');
				if (parsedLocalStorage) {
					const { tokenID, token } = parsedLocalStorage;

					try {
						await store.dispatch('login/doLogin', {
							method: 'token-login',
							parameters: {
								apiUser: tokenID,
								token
							}
						});
					} catch (err) {
						// do Login may fail here
						if (process.env.development) {
							console.log('pwaReAuth login failed', process.client && err);
						}
					}
				}
			} catch (err: any) {
				// ignore login errors
				if (process.env.development) {
					console.log('something failed during login', process.client && err);
				}
			}
		}

		if (process.client) {
			if (store.state.login.loggedIn) {
				if (!clientLoginInitialized) {
					clientLoginInitialized = true;
					EventBus().$emit('logged-in', {
						user: store.state.user.profile.obj,
						versionOkay: store.state.user.profile.versionOkay,
						bouncedEmails: store.state.user.profile.bouncedEmails
					});
				}
			} else {
				store.commit('login/loggedOut', undefined, { root: true });
			}
		}
	};

	if (process.server) {
		await doLogin();
		// on server side requests, set cache control to private if the user is logged in
		if (store.state.login.loggedIn) {
			res.setHeader('Cache-Control', 'private');
		}
	} else {
		// on client, do not wait till this process is done
		doLogin().catch(err => {
			const sentry = $sentry || app.$sentry;
			if (sentry) {
				sentry.captureError(err);
			}
		});
	}
};
