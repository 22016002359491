
import Vue from 'vue';
import type { IAPILoginUser } from '@hokify/common';
import { piniaAvailable } from '../helpers/piniaStoreAvailable';
import { externalLinkHelper } from '../helpers/externalLink';
import { nativeLinkOpener } from '../helpers/nativeLinkOpener';

export default Vue.extend({
	name: 'HokLink',
	props: {
		to: {
			type: String,
			required: true
		},
		id: {
			type: String
		},
		name: {
			type: String
		},
		/** performance hint: if set, check via vue-router for internal or external link is skipped */
		linkType: {
			type: String // external | internal
		},
		target: {
			type: String
		},
		ariaLabel: {
			type: String
		},
		domainRegion: {
			type: String,
			default: undefined
		},
		styling: {
			type: String,
			default: undefined,
			validator: (value: string) => ['inline-block', 'typeform'].includes(value)
		}
	},
	methods: {
		topLevelDomain($parent): string | undefined {
			return piniaAvailable($parent?.$nuxt, '$userRootStore')
				? $parent.$nuxt.context.$userRootStore?.topLevelDomain
				: $parent?.$store?.state?.topLevelDomain;
		},
		user($parent): IAPILoginUser | undefined {
			return piniaAvailable($parent?.$nuxt, '$userProfileStore')
				? $parent.$nuxt.context.$userProfileStore?.obj
				: $parent?.$store?.state?.user?.profile?.obj;
		},
		linkClasses(styling: string, myClass: string[]) {
			switch (styling) {
				case 'typeform':
					return ['inline', ...myClass];
				case 'inline-block':
					return ['inline-block', ...myClass];
				default:
					return ['inline-flex', ...myClass];
			}
		},
		isAppLink(toLink: string, linkType: string | undefined, $parent) {
			if (linkType === 'internal') {
				return true;
			}
			if (linkType === 'external') {
				return false;
			}

			// on server side, we can handle all as nuxt links (app link = true)
			if (process.server) {
				return true;
			}

			// do we have this route? if not, we do like it is an external link
			const matchedRoute = $parent.$router.resolve(toLink);

			if (!matchedRoute || !matchedRoute.resolved?.matched?.length) {
				return false;
			}

			// prevent /company to match as a jobNr
			if (matchedRoute.resolved?.params?.jobNr === 'company') return false;

			return true;
		},
		toLink(to: string, $parent) {
			// support "absolute" urls including the base route
			const baseRoute = $parent.$router.options?.base;

			return baseRoute?.length > 1 && to.startsWith(baseRoute)
				? to.substr(baseRoute.length - 1)
				: to;
		},
		getDomainRegion(domainRegion: string | undefined, $parent) {
			const validRegions = ['at', 'de', 'ch'];

			// 1st: use provided region
			if (domainRegion && validRegions.includes(domainRegion)) {
				return domainRegion;
			}

			// 2nd: use top level domain from store
			const tld = this.topLevelDomain($parent);
			if (tld && validRegions.includes(tld)) {
				return tld;
			}

			// 3rd: use region from user obj
			const userObj = this.user($parent);
			if (userObj) {
				const countryCode =
					userObj.general?.address?.countryCode?.toLowerCase() ||
					userObj.internal?.region?.toLowerCase();

				if (validRegions.includes(countryCode)) {
					return countryCode;
				}
			}

			// 4th: fallback to .com
			return 'com';
		},
		externalLink(to: string, target: string, domainRegion: string) {
			return externalLinkHelper(to, target, domainRegion);
		},
		startInappBrowser($event: Event, externalLink: string) {
			const isCordova =
				(process.client && window.location.protocol === 'file:') || process.env.cordova;
			// only on cordova
			if (isCordova) {
				nativeLinkOpener(externalLink, '_blank', $event, 'location=yes');
			}
		},
		externalRel(to: string) {
			const isAdjustLink = to?.startsWith('https://67ra.adj.st');
			if (isAdjustLink) {
				return 'noopener noreferrer nofollow';
			}
			return 'noopener noreferrer';
		}
	}
});
