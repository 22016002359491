
import { Component, Vue } from 'vue-property-decorator';
import WebsiteMain from '~/components/website/WebsiteMain.vue';
import WebsiteHeader from '~/components/website/header/WebsiteHeader.vue';
import WebToAppBanner from '~/components/website/header/WebToAppBanner.vue';

@Component({
	name: 'DefaultUserLayout',
	components: {
		WebsiteMain,
		WebsiteHeader,
		WebsiteFooter: () => import('~/components/website/footer/WebsiteFooter.vue'),
		WebToAppBanner
	}
})
export default class DefaultUserLayout extends Vue {}
