
import { Component, Vue, Watch } from 'vue-property-decorator';
import WebsiteMain from '~/components/website/WebsiteMain.vue';
import WebsiteHeader from '~/components/website/header/WebsiteHeader.vue';

@Component({
	name: 'ContentCompanyLayout',
	components: {
		WebsiteMain,
		WebsiteHeader,
		WebsiteFooter: () => import('~/components/website/footer/WebsiteFooter.vue')
	}
})
export default class ContentCompanyLayout extends Vue {
	categories = [
		{ name: 'Recruiting', value: 'recruiting', _id: 1 },
		{ name: 'Downloads', value: 'downloads', _id: 2 },
		{ name: 'Partner', value: 'partners', _id: 3 },
		{ name: 'Events', value: 'events', _id: 4 },
		{ name: 'Webinare', value: 'webinars', _id: 5 },
		{ name: 'Podcasts', value: 'podcasts', _id: 6 }
	];

	mounted() {
		this.$trackRecruiterEvent({}, 'view');
	}

	@Watch('$route') onRouteChange() {
		this.$trackRecruiterEvent({}, 'view');
	}
}
