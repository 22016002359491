import type { IAPIJobPreviewForUser, IAPIOtherTerms } from '@hokify/common';

export interface IJobsState {
	customBackLinkUrl: string;
	lastJobPageNumber: { page: number; beautifiedUrl: string };
	numberOfSimilarJobs: string;
	previewJob?: IAPIJobPreviewForUser;
	otherTerms?: IAPIOtherTerms;
}

export const defaultState = (): IJobsState => ({
	customBackLinkUrl: '',
	lastJobPageNumber: { page: 0, beautifiedUrl: '' },
	numberOfSimilarJobs: '',
	previewJob: undefined,
	otherTerms: undefined
});

export default defaultState;
