import type { IAPIPageHelpUserFAQ } from '@hokify/common';

export interface IFaqState {
	faqSectionList: IAPIPageHelpUserFAQ[];
}

export const defaultState = (): IFaqState => ({
	faqSectionList: []
});

export default defaultState;
