import { AxiosRequestConfig } from 'axios';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IJobsState } from '~/store/jobs/state';

export const actions: ActionTree<IJobsState, IRootState> = {
	async loadJobDetail(_, { jobNr, previewCode }: { jobNr?: number; previewCode?: string } = {}) {
		if (typeof jobNr === 'undefined') {
			throw new TypeError('loadJobDetail: jobNr is required');
		}
		const params: { [key: string]: string } = {};

		if (typeof previewCode !== 'undefined') {
			params.previewCode = String(previewCode);
		}
		const result = await this.$axios.$get(
			`/website-api/jobs/detail/${jobNr}${
				params ? `?${new URLSearchParams(params).toString()}` : ''
			}`
		);
		return result;
	},

	async loadJobsUrl(
		{ dispatch },
		{
			url,
			page,
			returnFilters,
			clientInteraction,
			radius
		}: {
			url?: string;
			page?: number;
			returnFilters?: boolean;
			clientInteraction?: boolean;
			radius?: string;
		} = {}
	) {
		const [experimentalSearchDiversity, searchScoreHighDirectMatch, searchScoreLowRootFields] =
			await Promise.all([
				dispatch('feature-flag/getFeatureFlagEnabledByName', 'searchDiversity', { root: true }),
				dispatch('feature-flag/getFeatureFlagEnabledByName', 'searchScoreHighDirectMatch', {
					root: true
				}),
				dispatch('feature-flag/getFeatureFlagEnabledByName', 'searchScoreLowRootFields', {
					root: true
				})
			]);

		const params: Record<string, string> = {
			clientInteraction: (!!clientInteraction).toString(),
			experimentalSearchDiversity,
			searchScoreHighDirectMatch,
			searchScoreLowRootFields
		};
		if (typeof url !== 'undefined' && url) {
			params.url = url;
		}
		if (typeof page !== 'undefined' && page) {
			params.page = String(page);
		}
		if (typeof returnFilters !== 'undefined') {
			params.returnFilters = String(returnFilters);
		}
		if (radius) {
			params.radius = radius;
		}
		const result = await this.$axios.$get(
			`/website-api/relations/list?${params ? new URLSearchParams(params).toString() : ''}`
		);
		return result;
	},

	async loadJobsSearch({ dispatch }, payload) {
		if (!payload) {
			payload = {};
		}

		const [experimentalSearchDiversity, searchScoreHighDirectMatch, searchScoreLowRootFields] =
			await Promise.all([
				dispatch('feature-flag/getFeatureFlagEnabledByName', 'searchDiversity', { root: true }),
				dispatch('feature-flag/getFeatureFlagEnabledByName', 'searchScoreHighDirectMatch', {
					root: true
				}),
				dispatch('feature-flag/getFeatureFlagEnabledByName', 'searchScoreLowRootFields', {
					root: true
				})
			]);
		const params: AxiosRequestConfig = {
			experimentalSearchDiversity,
			searchScoreHighDirectMatch,
			searchScoreLowRootFields
		};

		const result = await this.$axios.$post(`/website-api/relations/list`, payload, { params });
		return result;
	},

	async sendJob(_, payload) {
		const result = await this.$axios.$post('/website-api/jobs/job-send', payload);
		return result;
	},

	async updateCustomBackLinkUrl({ commit }, backLinkUrl: string) {
		commit('setCustomBackLinkUrl', backLinkUrl);
		return backLinkUrl;
	},

	async updateNumberOfSimilarJobs({ commit }, numberOfSimilarJobs: string) {
		commit('setNumberOfSimilarJobs', numberOfSimilarJobs);
	},

	async updateLastJobPageNumber({ commit }, data: { page: number; beautifiedUrl: string }) {
		commit('setLastJobPageNumber', data);
	},

	async loadPreviewJob({ state, commit }, { jobId }: { jobId: string }) {
		if (typeof jobId === 'undefined') {
			throw new TypeError('loadPreviewJob: jobId is required');
		}

		const job = await this.$axios.$get(`/website-api/jobs/preview/${jobId}`);
		commit('updatePreviewJob', job);

		return state.previewJob;
	},

	async jobItemClicked(_, lpId?: string) {
		if (lpId) {
			// trigger request but don't await it on purpose
			this.$axios.$post(`/website-api/jobs/lp-job-click`, { lpId });
		}
	}
};

export default actions;
