import { delCookie } from '@hokify/shared-components/lib/helpers/cookie';
import { APIObjectType, APITypeObjectId } from '@hokify/common';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async getB2BOnboardingDetails(
		_,
		{ job, code, onboardingMode = 'optout' }: { job: string; code: string; onboardingMode?: string }
	) /* :Promise<IAPIPageB2BOnboardingDetail> */ {
		return this.$axios.$get(`/website-api/b2bonboarding/detail/${onboardingMode}/${job}/${code}`);
	},

	async addToBlocklistByJob(_, data) {
		const result = await this.$axios.$post('/website-api/general/blocklist-by-job', data);
		return result;
	},

	async completeOnboarding(
		_,
		payload: {
			jobId?: APITypeObjectId<APIObjectType.Job>;
			prepCode?: string;
			companyCase: string;
			selectedJobId?: APITypeObjectId<APIObjectType.Job>;
			selectedJobIds?: APITypeObjectId<APIObjectType.Job>[];
			publish?: boolean;
			organizationId: APITypeObjectId<APIObjectType.Organization>;
		}
	): Promise<{
		success: boolean;
		redirectUrl: string;
	}> {
		const result = await this.$axios.$post(
			'/website-api/b2bonboarding/complete-onboarding',
			payload
		);
		delCookie('onboarding');
		return result;
	}
};

export default actions;
