import type { MutationTree } from 'vuex';
import { IFeatureFlagState } from './state';

export const mutations: MutationTree<IFeatureFlagState> = {
	addFeatureFlag(state, flag: { name: string; enabled: boolean }) {
		state.featureFlags[flag.name] = flag.enabled;
	}
};

export default mutations;
