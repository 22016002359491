import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { ITrackViewPayload } from '~/store/types';
import { getGoogleAnalyticsClientId } from '@hokify/tracking/tracking.client';

export const actions: ActionTree<IRootState, IRootState> = {
	async trackAction(_, payload: ITrackViewPayload) {
		const result = await this.$axios.$post('/website-api/stats/track-action', {
			...payload,
			gaCID: await getGoogleAnalyticsClientId()
		});
		return result;
	}
};

export default actions;
