import type { GetterTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IB2BContentState } from '~/store/b2b-content/state';

export const getters: GetterTree<IB2BContentState, IRootState> = {
	getPodcastByAlias(state) {
		return alias => state.podcasts[alias];
	},
	getWebinarByAlias(state) {
		return alias => state.webinars[alias];
	}
};

export default getters;
