import { IAPIEmployee } from '@hokify/common';
import { IAPIArticleTeaser } from '@hokify/magazine-interface';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async loadTeam(): Promise<IAPIEmployee[]> {
		const result = await this.$axios.$get('/website-api/general/team');
		return result.employees;
	},
	async loadTeamDetail(
		_,
		alias: string
	): Promise<{ employee: IAPIEmployee; articles: IAPIArticleTeaser[] }> {
		const result = await this.$axios.$get(`/website-api/general/team/${alias}`);
		return result;
	}
};

export default actions;
