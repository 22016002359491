import { getCookie, setCookie } from '@hokify/shared-components/lib/helpers/cookie';
import { IncomingMessage, ServerResponse } from 'http';

const allTesterGroups = ['A', 'B'];

function getTesterGroup(): string {
	return allTesterGroups[Math.floor(Math.random() * allTesterGroups.length)];
}

export const setOnboardingCookie = (path: string, res: ServerResponse): void => {
	setCookie('onboarding', path, 1, res);
};

export const setTesterGroupCookie = (): void => {
	setCookie('testerGroup', getTesterGroup(), 7);
};

export const setLastJobSearchCookie = (beautifiedUrl: string, res: ServerResponse): void => {
	setCookie('lastJobSearch', beautifiedUrl, 7, res);
};

export const getLastJobSearchCookie = (req?: IncomingMessage): string | undefined => {
	let cookies;
	if (process.server && req) {
		cookies = req.headers && req.headers.cookie;
	} else if (process.client) {
		cookies = window.document.cookie;
	}

	const exisingLastJobSearch = cookies && getCookie('lastJobSearch', cookies);

	return exisingLastJobSearch;
};
