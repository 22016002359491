
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import type { IAPILoginUser } from '@hokify/common';

const loginStore = namespace('login');
const userProfile = namespace('user/profile');

@Component({
	name: 'HeaderNavDesktop'
})
export default class HeaderNavDesktop extends Vue {
	@loginStore.State('loggedIn') loggedIn;

	@userProfile.State('obj') user!: IAPILoginUser;

	@loginStore.Action('logout') logout;

	@Prop({ type: Array }) readonly categories!: { name: string; alias: string }[];

	showMagazineDropdown = false;

	showForYouDropdown = false;

	get isCompanyUser(): boolean {
		return this.user?.mode === 'company';
	}

	mounted() {
		window.addEventListener('click', this.closeOnOutsideClick, {
			passive: true
		});
	}

	beforeDestroy() {
		window.removeEventListener('click', this.closeOnOutsideClick);
	}

	closeOnOutsideClick(event) {
		const isClickInsideElement =
			(this.$refs.dropdownButton as Node)?.contains(event.target) ||
			(this.$refs.dropdownList as Node)?.contains(event.target) ||
			(this.$refs.dropdownButtonForYou as Node)?.contains(event.target) ||
			(this.$refs.dropdownListForYou as Node)?.contains(event.target);
		if (!isClickInsideElement) {
			this.showMagazineDropdown = false;
			this.showForYouDropdown = false;
		}
	}

	handleLinkClick(event, alias: string) {
		event?.preventDefault();
		this.showMagazineDropdown = false;
		this.showForYouDropdown = false;
		this.$router.push(alias);
	}

	toggleForYouDropdown() {
		this.showForYouDropdown = !this.showForYouDropdown;
		this.showMagazineDropdown = false;
	}

	toggleMagazineDropdown() {
		this.showMagazineDropdown = !this.showMagazineDropdown;
		this.showForYouDropdown = false;
	}
}
