
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { createObserver, resolvableComponentFactory } from '../helpers/lazy';
import { hashCode } from '../helpers/hashcode';

@Component({
	name: 'HokImg'
})
export default class HokImg extends Vue {
	private initial = true;

	/**
	 * if set, this image is loaded first, and only when image gets into visible area it's switched to
	 * the real one
	 */
	@Prop({ type: String }) readonly preview?: string;

	@Prop({ type: [String, Object], required: true }) readonly source!:
		| string
		| { thumbnail: string; small: string; medium: string; large: string; xlarge: string };

	@Prop({ type: String, default: '' }) readonly alt!: string;

	@Prop({ type: Boolean, default: false }) readonly ssr!: boolean;

	@Prop({ type: String, default: 'medium' }) readonly size!: string;

	// setting intrinsic width and height of image is vital for modern browsers
	// https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
	@Prop({ type: [Number, String] }) readonly width!: number | string;

	@Prop({ type: [Number, String] }) readonly height!: number | string;

	@Watch('source') onSourceChange() {
		this.component = undefined;
	}

	@Watch('styling') onSizeChange() {
		this.component = undefined;
	}

	component!: object | undefined;

	data() {
		return {
			component: undefined
		};
	}

	render(createElement) {
		const { $el }: { $el?: Element } = this;
		const tag = $el?.tagName; // must be svg
		const renderedImage = $el?.getAttribute?.('src'); // currently rendered img
		if (
			$el?.nodeType === 1 &&
			tag?.toUpperCase() === 'IMG' &&
			renderedImage === this.imageUrl &&
			this.initial &&
			$el?.childElementCount === 0 // only when we have an element, and child element count is 0
		) {
			// this component is already rendered on serverside, just reuse it
			this.initial = false; // we can only hydrate once ;)
			return this.myRender(createElement, true);
		}

		return this.myRender(createElement);
	}

	get myclass() {
		const myclass: string[] = [
			process.server && this.$vnode.data?.class, // for ssr
			process.server && this.$vnode.data?.staticClass // for ssr
		];

		return myclass;
	}

	get imageUrl(): string {
		return this.source && (typeof this.source === 'string' ? this.source : this.source[this.size]);
	}

	myRender(createElement, domExists?) {
		if (!this.component) {
			const data: any = {
				class: this.myclass,
				ref: 'image',
				directives: this.$vnode.data?.directives,
				key: `img-${hashCode(this.imageUrl)}`,
				on: { click: ev => this.$emit('click', ev), error: ev => this.$emit('error', ev) }
			};

			const loading = {
				render: h => h('img', data)
			};

			if (domExists && (!this.preview || this.ssr)) {
				this.component = () => ({
					component: new Promise(_resolve => {}), // never resolving promise, prevents hokimg from client hydration
					delay: 0,
					loading
				});
			} else {
				const observer = createObserver({});

				const resolvableComponent = resolvableComponentFactory(() => ({
					render: h =>
						h('img', {
							...data,
							domProps: {
								loading: 'lazy',
								src: this.imageUrl,
								alt: this.alt,
								width: this.width,
								height: this.height
							}
						})
				}));

				// if we have a preview, show this first
				// and if we are on the server side, and have no SSR flag, we output an empty img tag first
				if ((this.preview || process.server) && !this.ssr) {
					this.component = {
						render: h =>
							h('img', {
								domProps: {
									loading: 'lazy',
									src: this.preview || null,
									alt: this.alt,
									width: this.width,
									height: this.height
								}
							}),
						mounted: () => {
							// If Intersection Observer API is not supported, hydrate immediately.
							if (!observer) {
								// eslint-disable-next-line no-underscore-dangle
								(resolvableComponent as any)._resolve();
								return;
							}

							// eslint-disable-next-line no-underscore-dangle
							(this.$el as any).hydrate = (resolvableComponent as any)._resolve;
							const cleanup = () => observer.unobserve(this.$el);
							resolvableComponent.then(cleanup);
							observer.observe(this.$el);
						}
					};
					resolvableComponent.then(() => {
						// when it is in visible area, we replace the current component with the real image
						this.component = () => ({
							component: resolvableComponent
						});
					});
				} else {
					if (process.server || this.ssr) {
						// if ssr, we resolve immediately
						(resolvableComponent as any)._resolve();
					}

					this.component = () => ({
						component: resolvableComponent,
						delay: process.server || this.ssr ? 200 : 0,
						loading: {
							...loading,
							mounted: () => {
								// If Intersection Observer API is not supported, hydrate immediately.
								if (!observer) {
									// eslint-disable-next-line no-underscore-dangle
									(resolvableComponent as any)._resolve();
									return;
								}

								// eslint-disable-next-line no-underscore-dangle
								(this.$el as any).hydrate = (resolvableComponent as any)._resolve;
								const cleanup = () => observer.unobserve(this.$el);
								resolvableComponent.then(cleanup);
								observer.observe(this.$el);
							}
						}
					});
				}
			}
		}

		return createElement(this.component);
	}
}
