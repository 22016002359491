import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { IAPIArticleTeaser } from '@hokify/magazine-interface';
import type { IMagazineState, IAPIPagePressLinks } from './state';

export const mutations: MutationTree<IMagazineState> = {
	setArticleTeasers(state, articleTeasers: { [key: string]: IAPIArticleTeaser[] }) {
		// filter out empty arrays from api response
		Object.keys(articleTeasers).forEach(
			key => !articleTeasers[key].length && delete articleTeasers[key]
		);
		state.articleTeasers = articleTeasers;
	},
	updateArticleTeasers(
		state,
		{ newArticleTeasers, category }: { newArticleTeasers: IAPIArticleTeaser[]; category: string }
	) {
		// check if category already exists -> else push items to exisiting category
		if (!(category in state.articleTeasers)) {
			state.articleTeasers[category] = newArticleTeasers;
		} else {
			newArticleTeasers.forEach(teaser => {
				if (!state.articleTeasers[category].find(t => t.alias === teaser.alias)) {
					state.articleTeasers[category].push(teaser);
				}
			});
		}
	},
	updatePressLinks(state, pressLinks: IAPIPagePressLinks[]) {
		pressLinks.forEach(updatePressLink => {
			const updated = state.pressLinks.some((pressLink, index) => {
				if (pressLink.title === updatePressLink.title) {
					Vue.set(state.pressLinks, index, updatePressLink);
					return true;
				}
				return false;
			});

			if (!updated) {
				state.pressLinks.push(updatePressLink);
			}
		});
	}
};

export default mutations;
