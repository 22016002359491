
import { Component, Vue, Watch } from 'vue-property-decorator';
import WebsiteMain from '~/components/website/WebsiteMain.vue';
import WebsiteHeader from '~/components/website/header/WebsiteHeader.vue';

@Component({
	name: 'PlainCompanyLayout',
	components: {
		WebsiteMain,
		WebsiteHeader,
		WebsiteFooter: () => import('~/components/website/footer/WebsiteFooter.vue')
	},
	head: {
		title: 'Job inserieren ➞ Mitarbeiter finden - hokify mobile Job-Plattform'
	}
})
export default class PlainCompanyLayout extends Vue {
	mounted() {
		this.$trackRecruiterEvent({}, 'view');
	}

	@Watch('$route') onRouteChange() {
		this.$trackRecruiterEvent({}, 'view');
	}
}
