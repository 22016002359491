export interface IUiStateState {
	isActive: string;
	doNotDisturbMode: boolean;
	hasClosedAppBanner: boolean;
	fromRoute?: string;
}

export const defaultState = (): IUiStateState => ({
	isActive: '',
	doNotDisturbMode: false,
	hasClosedAppBanner: false,
	fromRoute: undefined
});

export default defaultState;
