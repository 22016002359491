import Vue from 'vue';
import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { EventBus } from '@hokify/shared-components/lib/eventbus';

// import { checkSocket, connectSocket } from '~/helpers/socket';
// import type { Index } from '~/helpers/eventbus';

function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; i += 1) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export default ctx => {
	const { app, store } = ctx;

	/** WEB SOCKET * */
	/*
	if (store.state.login.loggedIn) {
		connectSocket(store.state.user.profile.obj, store);
	}
	Index.$on('logged-in', ({ user }) => {
		checkSocket(user, store);
	});
 */

	/* ONLINE / OFFLINE */
	window.addEventListener('load', () => {
		function updateOnlineStatus() {
			if (!navigator.onLine) {
				Vue.snack.show({
					title: 'Keine Verbindung',
					text: 'Bitte überprüfe deine Internetverbindung!'
				});
				ctx.deviceOffline = true;
			} else {
				ctx.deviceOffline = false;
			}
		}

		window.addEventListener('online', updateOnlineStatus);
		window.addEventListener('offline', updateOnlineStatus);
	});

	EventBus().$on('showCookieBanner', () => {
		Vue.prototype.$cookieBanner.toggle(true);
	});

	/** ADD TO HOMESCREEN */
	let deferredAddToHomeScreenPrompt;
	// based on https://developers.google.com/web/fundamentals/app-install-banners/
	window.addEventListener('beforeinstallprompt', e => {
		// console.log('beforeinstallprompt');
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		e.preventDefault();

		const addToHomeScreenDecision = lsTest() && localStorage.getItem('addToHomeScreenDecision');
		switch (addToHomeScreenDecision) {
			case 'enabled':
			case 'notinterested':
				// cancel here
				return;
			default:
				// Stash the event so it can be triggered later.
				deferredAddToHomeScreenPrompt = e;
				// console.log('addToHomeScreen event captured :-)');

				store.commit('addToHomeScreen', true);
		}
	});

	Vue.prototype.$addToHomeScreen = async activate => {
		if (!activate) {
			if (lsTest()) {
				localStorage.setItem('addToHomeScreenDecision', 'notinterested');
			}
			store.commit('addToHomeScreen', false);
			return false;
		}

		if (!deferredAddToHomeScreenPrompt) {
			// eslint-disable-next-line no-alert
			alert('autsch, add to home screen promise is null?');
			return false;
		}

		// Show the prompt
		deferredAddToHomeScreenPrompt.prompt();

		// Wait for the user to respond to the prompt

		const timeoutError = new Error('user did not response');
		// Create a promise that rejects in <ms> milliseconds
		const timeout = new Promise((_resolve, reject) => {
			const id = setTimeout(() => {
				clearTimeout(id);

				reject(timeoutError);
			}, 35000);
		});

		return Promise.race([
			timeout,
			deferredAddToHomeScreenPrompt.userChoice.then(choiceResult => {
				if (choiceResult.outcome === 'accepted') {
					if (lsTest()) {
						localStorage.setItem('addToHomeScreenDecision', 'enabled');
					}
					store.commit('addToHomeScreen', false);
					return true;
				}

				store.commit('addToHomeScreen', false);
				// console.log('User dismissed the A2HS prompt');
				return false;
			})
		]).catch(err => {
			store.commit('addToHomeScreen', false);
			if (app.$sentry) {
				app.$sentry.setExtra('error', err);
				app.$sentry.captureException(err);
			}
			console.error(err);
			return false;
		});
	};

	// PUSH
	(function pushNotification() {
		// console.log('PUSH CHECK :-)');

		// Check the current Notification permission.
		// If its denied, it's a permanent block until the
		if (!('Notification' in window)) {
			console.log('This browser does not support desktop notification');
			return;
		}

		// user changes the permission
		if (Notification.permission === 'denied') {
			console.log("The user has blocked notifications or it's not available");
			return;
		}

		// Check if push messaging is supported
		if (!('PushManager' in window)) {
			console.log("Push messaging isn't supported.");
			return;
		}

		if ('serviceWorker' in navigator) {
			// console.log('checking service worker...');
			const askPushPermission = async enable => {
				if (!enable) {
					store.commit('setupPushNotification', false);
					return false;
				}

				return new Promise((resolve, reject) => {
					const permissionResult = Notification.requestPermission(result => {
						resolve(result);
					});

					if (permissionResult) {
						permissionResult.then(resolve, reject);
					}
				})
					.then(permissionResult => {
						if (permissionResult !== 'granted') {
							// error.permissionResult = permissionResult;
							throw new Error("We weren't granted permission.");
						}
					})
					.then(() => navigator.serviceWorker.ready)
					.then(serviceWorkerRegistration =>
						serviceWorkerRegistration.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: urlB64ToUint8Array(
								'BJk34uQGF5g0wkbD7xK65NejmRt7LTBW1vgVaoHo4vm938ENx_mTGArPZLtWFkQdWGppC5Siu8zBLTx4BCuD4Zo'
							)
						})
					)
					.then(subscription => {
						const subscriptionObject = JSON.parse(JSON.stringify(subscription)); // indeed, we need this.. otherwise keys are not exported.. and no chance to get them (05/04/16 - chrome 50)
						store.dispatch('refreshPushOnServer', subscriptionObject);
						store.commit('setupPushNotification', false);
						return true;
					})
					.catch(e => {
						store.commit('setupPushNotification', false);
						if (Notification.permission === 'denied') {
							// The user denied the notification permission which
							// means we failed to subscribe and the user will need
							// to manually change the notification permission to
							// subscribe to push messages
							console.warn('Permission for Notifications was denied');
						} else if (
							e &&
							e.name !== 'AbortError' &&
							e.message !== "We weren't granted permission."
						) {
							console.error('Unable to subscribe to push.', e);
							if (app.$sentry) {
								app.$sentry.captureMessage('subscription failed', {
									level: 'info',
									extra: { error: e }
								});
							}
						}
						return false;
					});
			};

			Vue.prototype.$askPushPermission = askPushPermission;

			/* Zurzeit kein Case wo Subscription key geupdatet werden kann; Stand: 30.03.2020 */
			// // We need the service worker registration to check for a subscription
			// navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
			// 	// console.log('service worker is ready!');
			// 	// Are Notifications supported in the service worker?
			// 	if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
			// 		console.log("Notifications aren't supported.");
			// 		return;
			// 	}

			// 	serviceWorkerRegistration.pushManager.getSubscription().then(subscription => {
			// 		// console.log("getSubcrption result", subscription);
			// 		if (subscription) {
			// 			// keep server in sync
			// 			const subscriptionObject = JSON.parse(JSON.stringify(subscription)); // indeed, we need this.. otherwise keys are not exported.. and no chance to get them (05/04/16 - chrome 50)
			// 			store.commit('setupPushNotification', false);
			// 			store.dispatch('refreshPushOnServer', subscriptionObject);
			// 			return true;
			// 		}

			// 		if (Notification.permission === 'granted') {
			// 			return askPushPermission(true);
			// 		}

			// 		store.commit('setupPushNotification', true);
			// 		return false;
			// 	});
			// });

			// Handler for messages coming from the service worker
			navigator.serviceWorker.addEventListener('message', evt => {
				console.log('hokify postMessage received', evt.data);
				switch (evt.data.action) {
					case 'trackPageEvent':
						store.dispatch('trackPushEvent', evt.data.oid);
						break;
					case 'reload':
						console.log('SW forcing reload...');
						window.location.reload();
						break;
					default:
						break;
				}
				evt.ports[0]?.postMessage('got it');
			});

			// check for new version
			navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
				serviceWorkerRegistration.addEventListener('updatefound', () => {
					const newWorker = serviceWorkerRegistration.installing;

					if (!newWorker) {
						return;
					}
					newWorker.addEventListener('statechange', () => {
						// Has network.state changed?
						switch (newWorker.state) {
							case 'installed':
								if (navigator.serviceWorker.controller) {
									// new update available
									Vue.snack.success({
										text: 'Eine neue Version von hokify ist verfügbar!',
										button: 'Neu laden',
										action: () => {
											newWorker.postMessage({ action: 'skipWaiting' });
										}
									});
								}
								// No update available
								break;
							default:
							// nothing happening
						}
					});
				});
			});

			/*
			let refreshing;
			navigator.serviceWorker.addEventListener('controllerchange', function changeController() {
				if (refreshing) return;
				window.location.reload();
				refreshing = true;
			}); */
		}
	})();
};
