import type { IAPIPackageVariant } from '@hokify/common';

export interface IPriceListState {
	pricelist: IAPIPackageVariant[];
}

export const defaultState = (): IPriceListState => ({
	pricelist: []
});

export default defaultState;
