/* eslint-disable prefer-rest-params,no-sequences,prefer-destructuring,no-multi-assign */
import { genCookie } from '@hokify/shared-components/lib/helpers/cookie';

export default ({ route }) => {
	/**
	 * checks if "loginAuthCode" or "loginIdentifier" is provided als url parameter, if so sets
	 * cookies so that the PWA can grab this information again later for a seamless and easy
	 * signup/login in the /apply/ typeform route
	 */
	const loginAuthCode = route.query?.loginAuthCode;
	const loginIdentifier = route.query?.loginIdentifier;

	if (loginIdentifier) {
		document.cookie = genCookie('loginIdentifier', loginIdentifier, 7); // save for maximum of 7 days
	}

	if (loginAuthCode) {
		document.cookie = genCookie('loginAuthCode', loginAuthCode, 7); // save for maximum of 7 days
	}
};
