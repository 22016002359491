import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { IAPICompany } from '@hokify/common';
import type { ICompanyState } from '~/store/company/state';

export const mutations: MutationTree<ICompanyState> = {
	updateTopCompanies(state, companies: IAPICompany[]) {
		companies.forEach(updateCompany => {
			const updated = state.topCompanies.some((company, index) => {
				if (company.alias === updateCompany.alias) {
					Vue.set(state.topCompanies, index, updateCompany);
					return true;
				}
				return false;
			});

			if (!updated) {
				state.topCompanies.push(updateCompany);
			}
		});
	},
	updateOtherCompanies(state, companies: IAPICompany[]) {
		companies.forEach(updateCompany => {
			const updated = state.otherCompanies.some((company, index) => {
				if (company.alias === updateCompany.alias) {
					Vue.set(state.otherCompanies, index, updateCompany);
					return true;
				}
				return false;
			});

			if (!updated) {
				state.otherCompanies.push(updateCompany);
			}
		});
	}
};

export default mutations;
