
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { createObserver, resolvableComponentFactory } from '../helpers/lazy';
import { hashCode } from '../helpers/hashcode';

@Component({
	name: 'HokBgImg'
})
export default class HokBgImg extends Vue {
	@Prop({ type: Boolean, default: true }) readonly bgCover!: boolean;

	@Prop({ type: [String, Object], required: true }) readonly source!:
		| string
		| { thumbnail: string; small: string; medium: string; large: string; xlarge: string };

	@Prop({ type: String, default: '' }) readonly alt!: string;

	@Prop({ type: Boolean, default: false }) readonly ssr!: boolean;

	@Prop({ type: String, default: 'medium' }) readonly size!: string;

	// setting intrinsic width and height of image is vital for modern browsers
	// https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
	@Prop({ type: [Number, String] }) readonly width!: number | string;

	@Prop({ type: [Number, String] }) readonly height!: number | string;

	@Watch('source') onSourceChange() {
		this.component = undefined;
	}

	@Watch('styling') onSizeChange() {
		this.component = undefined;
	}

	component!: object | undefined;

	data() {
		return {
			component: undefined
		};
	}

	get getUrl() {
		return { backgroundImage: this.imageUrl && `url("${this.imageUrl}")` };
	}

	get imageUrl(): string {
		return this.source && (typeof this.source === 'string' ? this.source : this.source[this.size]);
	}

	private initial = true;

	render(createElement) {
		const { $el }: { $el?: Element & { style?: { backgroundImage: string } } } = this;
		const tag = $el?.tagName; // must be svg
		const renderedImage = $el?.style?.backgroundImage; // currently rendered icon

		if (
			$el?.nodeType === 1 &&
			tag?.toUpperCase() === 'DIV' &&
			renderedImage === `url("${this.imageUrl}")` &&
			this.initial &&
			$el?.childElementCount === 0 // only when we have an element, and child element count is 0
		) {
			// this component is already rendered on serverside, just reuse it
			this.initial = false; // we can only hydrate once ;)
			return this.myRender(createElement, true);
		}

		return this.myRender(createElement);
	}

	get myclass() {
		// const icon = this.icon;
		const myclass: string[] = [
			this.bgCover ? 'bg-cover' : 'bg-contain',
			process.server && this.$vnode.data?.class, // for ssr
			process.server && this.$vnode.data?.staticClass // for ssr
		];

		return myclass;
	}

	myRender(createElement, domExists?) {
		if (!this.component) {
			const data: any = {
				class: this.myclass,
				directives: this.$vnode.data?.directives,
				key: `bgimg-${hashCode(this.imageUrl)}`,
				on: { click: ev => this.$emit('click', ev) },
				style: this.getUrl
			};

			const loading = {
				render: h => h('div', data)
			};

			if (domExists) {
				this.component = () => ({
					component: new Promise(_resolve => {}), // never resolving promise, prevents hokimg from client hydration
					delay: 0,
					loading
				});
			} else {
				const observer = createObserver({});

				const resolvableComponent = resolvableComponentFactory(() => ({
					render: h =>
						h(
							'div',
							{
								...data,
								domProps: {
									title: this.alt
								},
								scopedSlots: this.$scopedSlots
							},
							this.$slots?.default
						)
				}));

				if (process.server || this.ssr) {
					// on server we resolve immediately, as we do not have an observer anyway ;)
					(resolvableComponent as any)._resolve();
				}

				this.component = () => ({
					component: resolvableComponent,
					delay: process.server || this.ssr ? 200 : 0,
					loading: {
						...loading,
						mounted: () => {
							// If Intersection Observer API is not supported, hydrate immediately.
							if (!observer) {
								// eslint-disable-next-line no-underscore-dangle
								(resolvableComponent as any)._resolve();
								return;
							}

							// eslint-disable-next-line no-underscore-dangle
							(this.$el as any).hydrate = (resolvableComponent as any)._resolve;
							const cleanup = () => observer.unobserve(this.$el);
							resolvableComponent.then(cleanup);
							observer.observe(this.$el);
						}
					}
				});
			}
		}

		return createElement(this.component);
	}
}
