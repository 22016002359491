import type { ActionTree } from 'vuex';
import type { IAPIPackageVariant } from '@hokify/common';
import type { IRootState } from '~/store';
import type { IPriceListState } from './state';

export const actions: ActionTree<IPriceListState, IRootState> = {
	async getPricelist(
		{ commit },
		{ region, type }: { region: string; type?: string }
	): Promise<IAPIPackageVariant[]> {
		const pricelist = await this.$axios.$get(
			`/website-api/business/pricelist?region=${region.toLowerCase()}${type ? `&type=${type}` : ''}`
		);
		commit('savePricelist', pricelist);
		return pricelist;
	}
};

export default actions;
