
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { isChristmas } from '@hokify/shared-components/lib/helpers/datehelpers/holiday';
import { getCookie } from '@hokify/shared-components/lib/helpers/cookie';
import type { IAPILoginUser } from '@hokify/common';
import { getSupportPhone } from '@hokify/shared-components/lib/helpers/contact-info';
import HeaderNavDesktop from '~/components/website/header/HeaderNavDesktop.vue';
import HeaderNavDesktopCompany from '~/components/website/header/HeaderNavDesktopCompany.vue';
import HeaderNavMobile from '~/components/website/header/HeaderNavMobile.vue';
import MobileDropdown from '~/components/website/header/MobileDropdown.vue';
import LoginBase from '~/mixins/LoginBase';

const jobsStore = namespace('jobs');
const loginStore = namespace('login');
const userProfile = namespace('user/profile');

@Component({
	name: 'WebsiteHeader',
	components: {
		HeaderNavDesktop,
		HeaderNavDesktopCompany,
		HeaderNavMobile,
		MobileDropdown
	}
})
export default class WebsiteHeader extends Mixins(LoginBase()) {
	@loginStore.State('loggedIn') loggedIn;

	@userProfile.State('obj') user!: IAPILoginUser;

	@loginStore.Action('logout') logout;

	@jobsStore.State('customBackLinkUrl') customBackLinkUrl!: string;

	@jobsStore.State('numberOfSimilarJobs') numberOfSimilarJobs!: string;

	@Prop({
		type: String,
		default: 'user',
		validator: (value: string) => ['user', 'company'].includes(value)
	})
	readonly styling!: string;

	@Prop({ type: Boolean }) readonly minimal?: boolean;

	get onboardingCookieCheck() {
		const cookies = process.client && window.document.cookie;
		return cookies && getCookie('onboarding', cookies);
	}

	get toggleClass() {
		if (this.toggleState) {
			return 'open';
		}
		return '';
	}

	// TODO make endpoint that return all possible categories (don't want to do overview call in header)
	magazineCategories = [
		{
			name: 'Übersicht',
			alias: '/karriere'
		},
		{
			name: 'Ausbildung & Lehre',
			alias:
				this.$store.state.topLevelDomain === 'at'
					? '/karriere/ausbildung-lehre'
					: '/karriere/ausbildung-lehre/alles-zur-ausbildung-in-deutschland'
		},
		{
			name: 'Aktuelles zum Arbeitsmarkt',
			alias:
				this.$store.state.topLevelDomain === 'at'
					? '/karriere/arbeitsmarkt'
					: '/karriere/arbeitsmarkt/alles-zum-deutschen-arbeitsmarkt'
		},
		{
			name: 'Bewerbung & Lebenslauf',
			alias:
				this.$store.state.topLevelDomain === 'at'
					? '/karriere/bewerbung'
					: '/karriere/bewerbung/alles-zum-thema-lebenslauf-und-bewerbung-in-deutschland'
		},
		{
			name: 'Alles zur Jobsuche',
			alias:
				this.$store.state.topLevelDomain === 'at'
					? '/karriere/jobsuche'
					: '/karriere/jobsuche/jobsuche-in-deutschland'
		},
		{
			name: 'Arbeitsalltag & Arbeitsrecht',
			alias:
				this.$store.state.topLevelDomain === 'at'
					? '/karriere/arbeitsrecht'
					: '/karriere/arbeitsmarkt/arbeitsalltag-und-arbeitsrecht-in-deutschland'
		},
		{
			name: 'Neuigkeiten von hokify',
			alias: '/karriere/product-updates'
		},
		{
			name: 'Karriere-Quizzes',
			alias: '/lp/karriere-quiz'
		}
	];

	checkIsChristmas = isChristmas();

	supportPhone = getSupportPhone(this.$store.state.topLevelDomain);

	toggleState = false;

	async doLogout() {
		try {
			await this.logout();
			this.$snack.success({
				title: 'Erfolg!',
				text: `${this.isUser ? 'Du wurdest' : 'Sie wurden'} erfolgreich abgemeldet!`
			});
		} catch (err: any) {
			this.$errorHandler.call(this, err, 'Beim Abmelden ist etwas schief gelaufen.');
		}
	}

	handleToggle(action?: string): void {
		this.toggleState = !this.toggleState;
		this.$trackWebsiteEvent('click_menu_icon', {});
		if (action === 'logout') {
			this.doLogout();
		} else if (action === 'register') {
			this.setupHeaderForm();
		}
	}

	created() {
		if (process.client) {
			if (window.history.length <= 1 && this.$refs.historyback instanceof Element) {
				this.$refs.historyback.classList.add('hidden');
			}
		}
	}

	get isUser(): boolean {
		return this.styling === 'user';
	}

	get headerClasses(): string {
		switch (this.styling) {
			case 'company':
				return 'bg-color-main-business lg:fixed';
			case 'user':
			default:
				return 'bg-color-main lg:fixed';
		}
	}

	handleSupportRequest() {
		this.$trackWebsiteEvent('contact_by_tel', { pageElement: 'header' });
		if (this.$isMobile.phone) {
			this.$modal.show('contactSupport');
		} else {
			window.location.href = `tel:${this.supportPhone}`;
		}
	}
}
