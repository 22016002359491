import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async updateGeneral({ dispatch }, update) {
		if (!update || Object.keys(update).length === 0) {
			console.log('skipping empty user obj update');
			return false;
		}
		await dispatch('syncGeneralToDb', update);
	},
	async syncGeneralToDb({ dispatch, rootState }, update) {
		if (!update || Object.keys(update).length === 0) {
			console.info('syncGeneralToDb: nothing todo');
			// nothing to save
			return {};
		}

		const { doNotDisturbMode } = rootState.uistate;

		const res = await this.$axios
			.$post('/app-api/general-profile', update, {
				params: {
					triggerVerificationMessage: doNotDisturbMode ? false : undefined
				}
			})
			.catch(err => {
				console.log('no promise!');
				throw err;
			});

		if (!res) {
			return {};
		}

		// update user obj
		if (res && res.userUpdate) {
			dispatch('user/profile/updateElements', res.userUpdate, { root: true });
		}
		return res;
	}
};

export default actions;
