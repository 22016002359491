export interface IUserLoginState {
	loggedIn: null | boolean;
	reAuthData: null;
	sessionIdCookie: false | string;
}

export const defaultState = (): IUserLoginState => ({
	loggedIn: null,
	reAuthData: null,
	sessionIdCookie: false
});

export default defaultState;
