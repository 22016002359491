import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';
import type { IFaqState } from '~/store/faq/state';

export const actions: ActionTree<IFaqState, IRootState> = {
	async loadFaqSectionList({ state, commit }) {
		const faqData = await this.$axios.$get(`/website-api/help/user/faq`);
		commit('updateFaqSectionList', faqData.faq);

		return state.faqSectionList;
	}
};

export default actions;
