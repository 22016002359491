import Vue from 'vue';
import { IB2BContentState } from '~/store/b2b-content/state';

export function updateStateArray(
	state: IB2BContentState,
	childKey: string,
	array: any[],
	key: string
) {
	array?.forEach(updateChild => {
		const updated = state[childKey].some((child, index) => {
			if (child[key] === updateChild[key]) {
				Vue.set(state[childKey], index, updateChild);
				return true;
			}
			return false;
		});

		if (!updated) {
			state[childKey].push(updateChild);
		}
	});
}

export function generateQueryString(object: Object): string {
	if (!object) {
		return '';
	}

	const parameters: Record<string, string> = {};

	Object.keys(object).forEach(key => {
		parameters[key] = String(object[key]);
	});

	return new URLSearchParams(parameters).toString();
}
