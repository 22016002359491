<template>
	<input ref="input" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default (function AutocompleteImpl(x) {
	return x.default || x;
	// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
})(require('./autocompleteImpl'));
</script>
