import type { IAPIPageHelpBusinessFAQ, IAPIPageHelpBusinessTutorials } from '@hokify/common';

export interface IAPIContentCategory {
	images: any;
	videos: any;
	items: any;
	additional: any;
	description: any;
}

export interface IAPIBusinessLandingPage {
	main: IAPIContentCategory;
	marketingExperts: IAPIContentCategory;
	customer: IAPIContentCategory;
	introduction: IAPIContentCategory;
	whyHokify: IAPIContentCategory;
	dataProtection: IAPIContentCategory;
}

export interface IAPIProductsLandingPage {
	showActiveSourcing: boolean;
	userCount: string;
	main: IAPIContentCategory;
	advert: IAPIContentCategory;
	socialMedia: IAPIContentCategory;
	activeSourcing: IAPIContentCategory;
	hokifyClub: IAPIContentCategory;
	qrCodeApplication: IAPIContentCategory;
	ats: IAPIContentCategory;
}

export interface IBusinessState {
	faqSectionList: IAPIPageHelpBusinessFAQ[];
	tutorialSectionList: IAPIPageHelpBusinessTutorials[];
	landingPageData?: IAPIBusinessLandingPage;
}

export const defaultState = (): IBusinessState => ({
	faqSectionList: [],
	tutorialSectionList: [],
	landingPageData: undefined
});

export default defaultState;
