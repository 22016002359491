import Vue from 'vue';

import type { MutationTree } from 'vuex';
import type { ICustomerLPState } from '~/store/user/landingpage/state';

export const mutations: MutationTree<ICustomerLPState> = {
	updateLandingPageData(state, landingPageData) {
		Vue.set(state, 'main', landingPageData.main);
		Vue.set(state, 'introduction', landingPageData.introduction);
		Vue.set(state, 'customer', landingPageData.customer);
		Vue.set(state, 'dataProtection', landingPageData.dataProtection);
		Vue.set(state, 'jobCount', landingPageData.jobCount || '-');
		Vue.set(state, 'currentJobSearches', landingPageData.currentJobSearches || []);
		Vue.set(state, 'newJobs', landingPageData.newJobs || []);
		Vue.set(state, 'regionalJobCounts', landingPageData.regionalJobCounts || []);
		Vue.set(state, 'specialJobCounts', landingPageData.specialJobCounts || []);
		Vue.set(state, 'recentlyUpdatedJobs', landingPageData.recentlyUpdatedJobs || []);
		Vue.set(state, 'articlesOfTheWeek', landingPageData.articlesOfTheWeek || []);
		Vue.set(state, 'topCompanies', landingPageData.topCompanies || []);
		Vue.set(state, 'popularLandingPages', landingPageData.popularLandingPages || []);
	}
};

export default mutations;
