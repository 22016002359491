import { setCookie } from '@hokify/shared-components/lib/helpers/cookie';
import { Context } from '@nuxt/types';
import type NodeCache from 'node-cache';

const noopLoading = {
	finish: () => {
		/* empty fct */
	},
	start: () => {
		/* empty fct */
	},
	fail: () => {
		/* empty fct */
	},
	set: () => {
		/* empty fct */
	}
};

const $loading = () =>
	process.client &&
	window.$nuxt &&
	window.$nuxt.$loading &&
	window.$nuxt.$loading.start !== undefined
		? window.$nuxt.$loading
		: noopLoading;

function hasAxiosCache(ssrContext): ssrContext is { $axiosCache: { [domain: string]: NodeCache } } {
	return !!ssrContext.$axiosCache;
}

export default ({ app, $axios, $sentry, store, res, req, ssrContext }: Context) => {
	let requestsRunning = 0;

	// AXIOS STUFF
	$axios.onResponse(response => {
		const sentry = $sentry || app?.$sentry;

		requestsRunning -= 1;
		if (requestsRunning <= 0) {
			requestsRunning = 0;
			$loading().finish();
		}
		if (response?.status === 200) {
			if (store.state.globalNetworkErrors) {
				// console.log('reset global Network Errors');
				store.commit('resetGlobalNetworkErrors');
			}
		} else if (response?.status === 422 && sentry) {
			sentry.withScope(scope => {
				scope.setExtra('response', response);
				sentry.captureMessage('request responded with 422');
			});
		}

		if (process.env.development && process.env.VERBOSE) {
			console.log('response', response);
		}

		const sessionToken = response.data?.sessionToken || response.headers?.['x-session-token'];

		if (sessionToken) {
			store.commit('login/sessionIdCookie', {
				sessionToken
			});

			if (process.server && res && process.env.loginCookie) {
				const expires = store.state.login.sessionIdCookie === false ? -1 : undefined;
				setCookie(
					process.env.loginCookie,
					store.state.login.sessionIdCookie,
					expires,
					res,
					req.headers.host
				);
			}
		}
	});

	$axios.onRequest(config => {
		// only cache requests to website-api, and only if they are GET requests
		const isCacheAble = config.url?.includes('/website-api/') && config.method === 'get';

		if (
			process.server &&
			hasAxiosCache(ssrContext) &&
			ssrContext?.$axiosCache?.[store.state.topLevelDomain] &&
			isCacheAble
		) {
			config.cache = ssrContext.$axiosCache[store.state.topLevelDomain];
		}

		requestsRunning += 1;
		if (requestsRunning === 1) {
			$loading().start();
		}

		// set default timeout
		if (process.server) {
			config.timeout = 10000; // 10 seconds timeout, for server side this is more than enough
		} else {
			config.timeout = 0; // no timeout on client side (do not set timeouts due to long uploads)
		}

		/* if (process.env.development && process.env.VERBOSE) {
			console.log('request', config);
		} */

		// ensure it is undefined by default
		delete config.headers?.['x-session-token'];

		if (store.state.login.sessionIdCookie && config.useSessionToken !== false) {
			if (!config.headers) {
				config.headers = {};
			}
			config.headers['x-session-token'] = store.state.login.sessionIdCookie;
		}

		if (process.server) {
			if (!config.headers) {
				config.headers = {};
			}
			// for server side request, we add a no-limiter key for disabling the rate limiter
			config.headers['x-no-limiter-key'] = 'Ajd8823ha8ssf23asfjil023.a';
		}

		if (!config.headers) {
			config.headers = {};
		}
		// header for location
		config.headers['x-top-level-domain'] = store.state.topLevelDomain
			? store.state.topLevelDomain
			: 'com';
	});

	$axios.onError(error => {
		const sentry = $sentry || app.$sentry;
		const config = error.response?.config || error.config;

		requestsRunning -= 1;
		const axiosIsCancel = $axios.isCancel(error);
		if (!axiosIsCancel) {
			const { fail } = $loading();
			if (fail) {
				fail();
			}
		}

		if (requestsRunning <= 0) {
			requestsRunning = 0;
			$loading().finish();
		}
		if (axiosIsCancel) {
			if (process.env.development) {
				console.log('axios request cancelled');
			}
			return;
		}

		store.commit('increaseGlobalNetworkErrors');
		const code = error.response?.status;
		if (process.env.development && process.client) {
			console.log(
				'AXIOS GLOBAL ERROR HANDLER',
				code,
				error,
				config?.url || config,
				store.state.globalNetworkErrors
				// config && (!config.params || !config.params['x-no-global-login-handler'])
			);
		} else if (code === 500) {
			console.log('SERVER ERROR', error);
			if (sentry) {
				sentry.withScope(scope => {
					scope.setExtra('data', error.response?.data);
					scope.setExtra('status', error.response?.status);
					if (config) {
						scope.setExtra('config', {
							url: config.url,
							method: config.method,
							headers: config.headers,
							params: config.params
						});
					}
					sentry.captureException(error);
				});
			}
		}

		// fix axios errors on SSR side (devalue cannot serialize it otherwise)
		if (process.server) {
			if (!error.response) {
				const err: any = new Error(`Request failed: ${error.message}`);

				err.response = {
					config: config && {
						url: config.url,
						method: config.method,
						headers: config.headers,
						params: config.params
					}
				};
				err.isAxiosError = true;
				return Promise.reject(err);
			}

			// construct a new error object in the same form as the Axios error
			const err: any = new Error(`Request failed with status code ${error.response.status}`);
			err.response = {
				data: error.response.data,
				status: error.response.status,
				statusText: error.response.statusText,
				headers: error.response.headers,
				config: config && {
					url: config.url,
					method: config.method,
					headers: config.headers,
					params: config.params
				}
			};
			err.isAxiosError = true;
			return Promise.reject(err);
		}
	});
};
