// union symbol singleton types provide meaningful debugging, but more verbose than union string literal types
export enum LoginState {
	landing = 'landing',
	loading = 'loading',
	login = 'login',
	pwdReset = 'pwdReset',
	signup = 'signup',
	reserved = 'reserved',
	loggedIn = 'loggedIn',
	welcome = 'welcome'
}
