import type { IAPILandingPageAdditionalContent } from '@hokify/common';
import type { IAPIArticleTeaser } from '@hokify/magazine-interface';

export interface IAPICustomerLandingPage {
	main: IAPICustomerLandingPageCategory;
	introduction: IAPICustomerLandingPageCategory;
	customer: IAPICustomerLandingPageCategory;
	dataProtection: IAPICustomerLandingPageCategory;
	jobCount: number;
	currentJobSearches: IAPICustomerLandingPageInfoItem[];
	newJobs: IAPICustomerLandingPageInfoItem[];
	regionalJobCounts: IAPICustomerLandingPageInfoItem[];
	specialJobCounts: IAPICustomerLandingPageInfoItem[];
	recentlyUpdatedJobs: IAPICustomerLandingPageInfoItem[];
	articlesOfTheWeek: IAPIArticleTeaser[];
	topCompanies: IAPICustomerLandingPageInfoItem[];
	popularLandingPages: IAPILandingPageAdditionalContent[];
}

export interface IAPICustomerLandingPageInfoItem {
	title?: string;
	linkUrl?: string;
	recordCount?: number;
	description?: string;
	imageUrl?: string;
	recordDate?: Date;
}

export interface IAPICustomerLandingPageCategory {
	images?: string[];
	videos?: string[];
	logos?: string[];
	items?: IAPICustomerLandingPageItem[];
	additional?: any;
}

export interface IAPICustomerLandingPageItem {
	title: string;
	description: string;
	icon: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomerLPState extends IAPICustomerLandingPage {}

export const defaultState = (): ICustomerLPState => ({
	main: {},
	introduction: {},
	customer: {},
	dataProtection: {},
	jobCount: 0,
	currentJobSearches: [],
	newJobs: [],
	regionalJobCounts: [],
	specialJobCounts: [],
	recentlyUpdatedJobs: [],
	articlesOfTheWeek: [],
	topCompanies: [],
	popularLandingPages: []
});

export default defaultState;
