<template functional>
	<div class="flex flex-1 justify-end h-5 items-center">
		<div
			v-if="parent.$isMobile.any && parent.styling !== 'user'"
			class="mr-6 xl:mr-10"
			@click="parent.handleSupportRequest"
		>
			<HokIcon icon="icon-phone" color="white" pointer />
		</div>
		<button
			aria-label="Navigation Dropdown"
			:class="parent.toggleClass"
			class="header-nav-icon z-20"
			data-cy="mobileDropdown"
			@click="parent.handleToggle"
		>
			<span />
			<span />
			<span />
			<span />
		</button>
	</div>
</template>

<script>
export default {
	name: 'HeaderNavMobile'
};
</script>

<style scoped lang="scss">
// Styling for website mobile dropdown menu and animation
@mixin rotation($degrees) {
	transform: rotate($degrees);
}
@mixin easeinout($seconds) {
	transition: $seconds ease-in-out;
}

.header-nav-icon {
	width: 26px;
	height: 22px;
	position: relative;
	@include rotation(0deg);
	@include easeinout(0.5s);
	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: white;
		border-radius: 0px;
		opacity: 1;
		left: 0;
		@include rotation(0deg);
		@include easeinout(0.25s);
	}
	span:nth-child(1) {
		top: 0px;
	}
	span:nth-child(2),
	span:nth-child(3) {
		top: 9px;
	}
	span:nth-child(4) {
		top: 18px;
	}
	&.open {
		span:nth-child(1) {
			top: 9px;
			width: 0%;
			left: 50%;
		}
		span:nth-child(2) {
			@include rotation(45deg);
		}
		span:nth-child(3) {
			@include rotation(-45deg);
		}
		span:nth-child(4) {
			top: 9px;
			width: 0%;
			left: 50%;
		}
	}
}
</style>

<style scoped lang="scss">
.smaller-text {
	font-size: 0.75rem;
	margin-right: $s3;
	@media (min-width: 375px) {
		font-size: 1rem;
		margin-right: $s8;
	}
}
</style>
