import type { MutationTree } from 'vuex';
import type { IUiStateState } from './state';
import { defaultState } from './state';

export const mutations: MutationTree<IUiStateState> = {
	resetState(state) {
		Object.assign(state, defaultState());
	},
	updateHasClosedAppBanner(state, hasClosedAppBanner) {
		state.hasClosedAppBanner = hasClosedAppBanner;
	},
	updateFromRoute(state, fromRoute) {
		state.fromRoute = fromRoute;
	}
};

export default mutations;
