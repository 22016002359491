import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async getNotifications(_, describeType: string) {
		const result = await this.$axios.$get(
			`/website-api/notification/describe-type/${describeType}`
		);
		return result;
	},

	async disableNotification(
		_,
		{ describeType, payload }: { describeType: string; payload: { hash: string; userId: string } }
	) {
		const result = await this.$axios.$post(
			`/website-api/notification/disable/${describeType}`,
			payload
		);
		return result;
	}
};

export default actions;
