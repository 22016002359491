import { delCookie } from '@hokify/shared-components/lib/helpers/cookie';
import type { ActionTree } from 'vuex';
import type { IRootState } from '~/store';

export const actions: ActionTree<IRootState, IRootState> = {
	async doOnboardingSignUp({ rootState }, payload) {
		payload = {
			...payload,
			// add region if available
			location: rootState.topLevelDomain
		};
		const result = await this.$axios.$post('/website-api/b2bonboarding/onboarding', payload);
		delCookie('onboarding');
		return result;

		/* test for spinner and transition phase */
		// return new Promise(resolve => setTimeout(resolve, 5000)).then(() => {
		// 	console.log('ROOTSTATE', rootState);
		// 	console.log('PAYLOAD', payload);
		// });
	}
};

export default actions;
