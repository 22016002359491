import type { GetterTree } from 'vuex';
import type { LikeUser } from '@hokify/common';
import type { IRootState } from '~/store';
import type { IMatchesState } from './state';

export const getters: GetterTree<IMatchesState, IRootState> = {
	getAll(state) {
		return state.all
			.filter(obj => !!obj.match) // filter out invalid entries
			.map(obj => obj.match);
	},
	getStatusOfRelation(state) {
		return relation => {
			let status: LikeUser | undefined;
			state.all.some(m => {
				if (m?.match?.relation?.obj?._id === relation?.obj?._id) {
					status = m.match.likeuser;
					return true;
				}
				return false;
			});

			return status;
		};
	},
	getMatchByRelation(state) {
		return relation => {
			let matchObj;

			if (
				state.all
					.map(obj => obj.match)
					.some(m => {
						if (m.relation.obj._id === relation.obj._id) {
							matchObj = m;
							return true;
						}
						return false;
					})
			) {
				return matchObj;
			}

			throw new Error(`match not found for relation ${relation.obj._id}`);
		};
	}
};

export default getters;
