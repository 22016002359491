
import { defineComponent } from 'vue';

// set autofocus via JS for browsers which doesn't support HTML5 attr. focus or when page is not loaded
const focus = {
	inserted(el, binding, vnode) {
		const $iPhone = vnode?.context?.$isMobile?.apple?.device;
		if (!binding || $iPhone) {
			return;
		}
		if (vnode.context.autofocus && !el.gotFocused) {
			vnode.context.$emit('focus');

			setTimeout(() => {
				el.gotFocused = true;
				el.focus();
			}, 300); // by default wait 300ms, in case there is any transition ongoing (max 300 ms)
		}
	}
};

export default defineComponent({
	name: 'HokInput',
	directives: { focus },
	data() {
		return {
			focused: false
		};
	},
	computed: {
		myClasses() {
			const classes: string[] = [];
			if (this.focused || typeof this.value === 'number' || (this.value && this.value.length > 0)) {
				classes.push('input--filled');
			}
			if (this.focused) {
				classes.push('input--active');
			}
			if (this.fullwidth) {
				classes.push('max-w-full');
			}
			if (this.styling === 'white') {
				classes.push('styling-white');
			}
			return classes;
		},
		stylingWhite(): boolean {
			return this.styling === 'white';
		}
	},
	created() {
		if (this.id === 'hokinput') {
			console.warn('HokInput without explicit id', this);
		}
	},
	methods: {
		hasValue(object: any): object is { value: string } {
			return typeof object === 'object' && 'value' in object;
		},
		focus($event) {
			this.focused = true;
			this.$emit('focus', $event);
		},
		blur($event) {
			this.focused = false;
			this.$emit('blur', $event);
		}
	},
	props: {
		name: { type: String, default: '' },
		value: { type: [String, Number], default: '' },
		minLength: { type: Number },
		maxLength: { type: Number },
		max: { type: Number },
		min: { type: Number },
		inputmode: { type: String },
		pattern: { type: String },
		styling: { type: String, default: '' },
		id: { type: String, default: 'hokinput', required: true },
		browserAutocomplete: { type: [Boolean, String], default: 'on' },
		required: { type: Boolean, default: false },
		placeholder: { type: String, default: '' },
		type: { type: String, default: 'text' },
		autofocus: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		fullwidth: {
			type: [String, Boolean],
			default: false,
			validator: (value: string | boolean) => [false, 'mobile', 'always'].includes(value)
		}
	}
});
