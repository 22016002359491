
import Vue, { defineComponent } from 'vue';
import { IPlaceResult } from './types';
import GoogleAutocomplete from './internal/autocomplete.vue';
import ErrorBox from '../ErrorBox.vue';

const focus = {
	inserted(el, binding, vnode) {
		const $iPhone = vnode?.context?.$isMobile?.apple?.device;
		if (!binding || $iPhone) {
			return;
		}
		if (vnode.context.autofocus && !el.gotFocused) {
			vnode.context.$emit('focus');

			setTimeout(() => {
				el.gotFocused = true;
				el.focus();
			}, 300); // by default wait 300ms, in case there is any transition ongoing (max 300 ms)
		}
	}
};

function hasFocus(obj?: any): obj is { focus(): void } {
	return typeof obj?.focus === 'function';
}

function hasValue(obj?: any): obj is HTMLInputElement {
	return obj?.value !== undefined;
}

export default defineComponent({
	name: 'LocationAutocompleteExperimental',
	directives: { focus },
	components: { ErrorBox, GoogleAutocomplete },
	data() {
		const postalIsMissing = null as boolean | null;
		const error = { title: '', text: '' };

		return {
			focused: false,
			loading: false,
			postalIsMissing,
			internalValue: this.value,
			isBlurring: false,
			geoLocationAvailable: false,
			invalidValue: false,
			error
		};
	},
	computed: {
		autocompleteOptions() {
			// combined bounds from at, de, ch: https://gist.github.com/graydon/11198540
			const opts: {
				bounds: any;
				componentRestrictions?: { country: string };
				fields: string[];
				types?: string[];
			} = {
				bounds: {
					north: 54.983104153, // 4 >
					south: 45.7769477403, // 2 <
					west: 5.98865807458, // 1 <
					east: 16.9796667823 // 3 >
				},
				fields: ['name', 'formatted_address']
			};

			// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
			if (this.fullResults) {
				opts.fields = ['name', 'formatted_address', 'types', 'address_components'];
			} else if (this.requirePostal && this.onlyCities) {
				opts.fields = ['name', 'formatted_address', 'address_components'];
				opts.types = ['locality', 'postal_code'];
			} else if (this.requirePostal) {
				opts.fields = ['name', 'formatted_address', 'address_components'];
				opts.types = ['geocode'];
			}

			if (this.country) {
				opts.componentRestrictions = {
					country: this.country
				};
			}

			return opts;
		},
		autocomplete() {
			return this.$refs.autocomplete as Vue;
		},
		sizeClasses() {
			return {
				'h-12': this.size === 'medium',
				'h-16': this.size === 'large'
			};
		}
	},
	created() {
		if (this.id === 'locationautocomplete') {
			console.warn('LocationAutocomplete without explicit id', this);
		}
	},
	mounted() {
		this.geoLocationAvailable = typeof navigator !== 'undefined' && !!navigator.geolocation;
	},
	methods: {
		emitChange() {
			if (hasValue(this.autocomplete?.$refs?.input)) {
				if ((this.requirePostal && !this.postalIsMissing) || !this.requirePostal) {
					this.$emit(
						'input',
						this.autocomplete?.$refs?.input?.value,
						undefined,
						this.postalIsMissing
					);
				}
			}
		},
		input() {
			this.emitChange();
		},
		change() {
			this.emitChange();
			if (hasValue(this.autocomplete?.$refs?.input)) {
				if (!this.isBlurring) {
					const internalValue = this.autocomplete?.$refs?.input?.value;

					this.internalValue = internalValue;

					if ((this.requirePostal && !this.postalIsMissing) || !this.requirePostal) {
						this.$emit('change', internalValue, undefined, this.postalIsMissing);
					}
				}
			}
		},
		processPlaceChange(
			placeName: string | undefined,
			place?: IPlaceResult,
			postalIsMissing?: boolean | undefined
		) {
			if (postalIsMissing !== undefined) {
				this.postalIsMissing = postalIsMissing;
			} else if (place) {
				this.setPostalIsMissing(place);
			}

			// always emmit after setting this.postalIsMissing
			this.$emit('input', placeName, place, this.postalIsMissing);
			this.$emit('change', placeName, place, this.postalIsMissing);
		},
		setPlace(_place: IPlaceResult, input: string) {
			// we do a seperate geo coding and do not use the _place result (this happens on do blur action)
			if (input) {
				this.internalValue = input;
			}
			this.doBlur();
		},
		blur($event) {
			this.isBlurring = true;
			window.setTimeout(() => {
				this.doBlur($event);
				this.isBlurring = false;
			}, 300);
		},
		async doBlur($event?: any) {
			if ($event) {
				if (!this.focused) {
					return;
				}
				this.focused = false;

				this.$emit('blur', $event);
			}

			if (this.requirePostal) {
				let result;
				try {
					this.loading = true;

					// resolve location via given function
					if (this.resolveAddress) {
						result = await this.resolveAddress({ address: this.internalValue });
						let postalIsMissing = true;
						if (result.code) {
							postalIsMissing = false;
						}
						this.processPlaceChange(this.internalValue, result, postalIsMissing);
					}
					// resolve location via api
					else {
						await (this as any).$gmapApiPromiseLazy(); // can we instead use the plugin from the website-app? (gmap-vue.client.ts)

						const geocoder = new window.google.maps.Geocoder();

						result = await new Promise((resolve, reject) => {
							geocoder.geocode({ address: this.internalValue }, (geoResult, status) => {
								if (status !== 'OK') {
									// eslint-disable-next-line prefer-promise-reject-errors
									reject({ geoResult, status });
									return;
								}
								resolve(geoResult);
							});
						});
						this.processPlaceChange(this.internalValue, result && result[0]);
					}
				} catch (error: any) {
					if (error.status !== 'ZERO_RESULTS') {
						throw new Error(error);
					}
				} finally {
					this.loading = false;
				}
			} else {
				this.processPlaceChange(this.internalValue, { formatted_address: this.internalValue });
			}
		},
		focus() {
			this.focused = true;
		},
		click($event) {
			this.$emit('click', $event);
		},
		setGeolocation() {
			this.loading = true;
			navigator.geolocation.getCurrentPosition(
				async position => {
					if (position && position.coords) {
						try {
							const location = await this.resolveGeoCoords({
								long: position.coords.longitude,
								lat: position.coords.latitude
							});

							const { name } = location;

							if (location?.code) {
								this.postalIsMissing = false;
							}
							if (name) {
								this.processPlaceChange(name, {
									formatted_address: name,

									address_components: [
										{ types: ['country'], short_name: location.countryCode },
										{ types: ['postal_code'], short_name: location.code }
									]
								});
								this.internalValue = name;
							}
						} catch (err: any) {
							this.error = {
								title: 'Standort kann nicht aufgelöst werden',
								text: 'Bitte überprüfe die Adresse und probiere es noch einmal.'
							};
							this.$modal.show('location-modal');
						}
						this.loading = false;
					}
				},
				_err => {
					this.error = {
						title: 'Aktueller Standort nicht verfügbar',
						text: 'Bitte überprüfe in den Einstellungen ob du die Standortfreigabe aktiviert hast um die Autovervollständigung zu nutzen.'
					};
					this.$modal.show('location-modal');
					this.geoLocationAvailable = false;
					this.loading = false;
				}
			);
		},
		resetOrLocation() {
			if (this.disabled) {
				return;
			}

			this.focused = false;
			if (this.internalValue && this.internalValue.length > 0) {
				this.internalValue = '';
				this.processPlaceChange('');
			} else if (this.currentLocationButton) {
				this.setGeolocation();
			}
		},
		setPostalIsMissing(place: IPlaceResult) {
			if (this.requirePostal) {
				const hasPostal =
					place.address_components?.some(
						address => address.types.includes('postal_code') && address.short_name
					) || false;

				this.postalIsMissing = !hasPostal;
			}
		},
		changedValue(newValue) {
			this.internalValue = newValue;
		},
		closeModal() {
			this.$modal.hide('location-modal');
			if (hasFocus(this.autocomplete?.$refs?.input)) {
				this.autocomplete.$refs.input.focus();
			}
		}
	},
	props: {
		name: { type: String, default: '' },
		id: { type: String, default: 'locationautocomplete', required: true },
		value: { type: String, default: '' },
		disabled: { type: Boolean, default: false },
		currentLocationButton: { type: Boolean },
		autofocus: { type: Boolean, default: false },
		styling: {
			type: String,
			default: 'default',
			validator: (value: string) => ['default', 'cv', 'center', 'match-overview'].includes(value)
		},
		resolveGeoCoords: { type: Function, default: null },
		resolveAddress: { type: Function, default: null },
		country: { type: String },
		fullResults: { type: Boolean, default: () => false },
		requirePostal: { type: Boolean, default: false },
		onlyCities: { type: Boolean, default: false },
		placeholder: { type: String, default: '' },
		size: {
			type: String,
			default: 'medium',
			validator: (value: string) => ['medium', 'large'].includes(value)
		}
	},
	watch: {
		value: [
			{
				handler: 'changedValue'
			}
		]
	}
});
