import type { GetterTree } from 'vuex';
import type { IRootState } from '~/store';
import type { ICustomerLPState } from '~/store/user/landingpage/state';

export const getters: GetterTree<ICustomerLPState, IRootState> = {
	getMappedJobSearches(state) {
		return state.currentJobSearches.map(element => ({
			title: element.title,
			description: element.recordCount ? `${element.recordCount} Ergebnisse` : '',
			to: element.linkUrl
		}));
	},

	getMappedRegionalJobs(state) {
		return state.regionalJobCounts.map(element => ({
			title: `Jobs in ${element.title}`,
			description: element.recordCount ? `${element.recordCount} Ergebnisse` : '',
			to: element.linkUrl
		}));
	},

	getMappedSpecialJobCounts(state) {
		return state.specialJobCounts.map(element => ({
			title: element.title,
			description: element.recordCount ? `${element.recordCount} Ergebnisse` : '',
			to: element.linkUrl
		}));
	},

	getMappedNewJobs(state) {
		return state.newJobs.map(element => ({
			title: element.title,
			description: element.description,
			to: element.linkUrl
		}));
	},

	getMappedTopCompanies(state) {
		return state.topCompanies.map(company => ({
			title: company.title,
			to: company.linkUrl
		}));
	}
};

export default getters;
