
/* eslint-disable no-undef  */
import { defineComponent } from 'vue';
import { delay, newDeferredPromise } from '../../helpers/promise';

// set autofocus via JS
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const focus = {
	inserted(el, binding, vnode) {
		const $iPhone = vnode?.context?.$isMobile?.apple?.device;
		if (!binding || $iPhone) {
			return;
		}
		if (vnode.context.autofocus && !el.gotFocused) {
			vnode.context.$emit('focus');

			setTimeout(() => {
				el.gotFocused = true;
				el.focus();
			}, 300); // by default wait 300ms, in case there is any transition ongoing (max 300 ms)
		}
	}
};

function hasAxios(params): params is { $axios } {
	return !!params.$axios;
}

export default defineComponent({
	name: 'AutocompleteExperimental',
	components: {
		ErrorBox: () => import('../ErrorBox.vue'),
		Spinner: () => import('../Spinner.vue')
	},
	directives: { focus },
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	async fetch(this: AutocompleteExperimental) {
		/*
		 * valueId & valueLabel
		 * if set, we return the property valueId (e.g. "val")
		 * for the valueLabel (e.g. "label") as result.
		 * e.g. { val: "1", label: 'x', name: 'h' }
		 * will return as value "1" if the search term (=valueLabel) is "x".
		 *
		 * to get this value for the initial loading state,
		 * we need to execute a search for the current
		 * search term, and find the valueId property of it.
		 *
		 * beware: this is ALSO executed on server side!
		 */
		if (this.value && this.valueId && this.valueLabel) {
			await this.loadEntries();
			this.values.some(testValue => {
				if (this.internalValue === testValue[this.valueLabel]) {
					this.internalValue = testValue[this.valueLabel];
					this.internalValueId = testValue[this.valueId];
					return true;
				}
				return false;
			});
		}
	},
	data() {
		const waitForClick = undefined as any;
		const tt = undefined as any;

		return {
			error: false,
			isOpen: false,
			internalValueId: null,
			internalValue: this.value,
			current: -1,
			loading: false,
			entriesFetched: false,
			useSelection: false,
			values: [],
			invalidValue: false,
			selectedSuggestion: false,
			tt,
			waitForClick
		};
	},
	computed: {
		currentNumberOfSuggestions(): number {
			return this.values.length < this.maxNumberOfSuggestions
				? this.values.length
				: this.maxNumberOfSuggestions;
		},
		mustSelectComputed() {
			return this.mustSelect === true || this.mustSelect === false
				? this.mustSelect
				: !!this.valueLabel;
		},
		suggestions(): string[] {
			const values =
				(this.values?.length && this.values.slice(0, this.maxNumberOfSuggestions)) || [];

			// show all values, if user must select one of these
			if (this.valueLabel) {
				return values.map(v => v[this.valueLabel]);
			}
			return values;
		},
		valueRequired() {
			return this.$scopedSlots?.addCustomValue && this.isOpen && this.internalValue.length > 0;
		},
		openSuggestion() {
			if (
				(this.minLength > 0 && !this.internalValue) ||
				(this.internalValue && this.internalValue.length < this.minLength)
			) {
				return false;
			}
			return this.isOpen && (this.loading || (this.values && this.values.length !== 0));
		},
		sizeClasses() {
			return {
				'h-12': this.size === 'medium',
				'h-16': this.size === 'large'
			};
		}
	},
	created() {
		if (this.id === 'autocompleteinner') {
			console.warn('AutocompleteInner without explicit id', this);
		}
	},
	methods: {
		input($event) {
			this.internalValue = $event.target.value;
			if (!this.isOpen) {
				this.useSelection = true;
				this.isOpen = true;
			} else {
				const found = this.suggestions.indexOf(this.internalValue);
				if (found !== -1 && !this.useSelection) {
					this.useSelection = true;
					this.current = found;
				} else {
					this.useSelection = false;
					this.current = -1;
				}
				if (this.$scopedSlots?.addCustomValue) {
					this.isOpen = true;
				}
			}
			this.selectedSuggestion = false;
			if (!this.mustSelectComputed) {
				this.processEvent($event); //  $emit('input', $event.target.value);
			}
		},
		async matches() {
			if (
				this.minLength > 0 &&
				(!this.internalValue || this.internalValue.length < this.minLength)
			) {
				return [];
			}
			this.entriesFetched = true;
			return this.valuePromise(this.internalValue);
		},
		async loadEntries() {
			this.loading = true;
			try {
				this.values = await this.matches();
				this.error = false;
			} catch (err: any) {
				const axiosIsCancel = hasAxios(this) && this.$axios.isCancel(err);
				if (!axiosIsCancel) {
					// ignore cancel errors
					console.error(err);
					this.error = err;
				}
			} finally {
				this.loading = false;
			}
		},
		keyup(event) {
			if (!event.code || !event?.code?.includes('Arrow')) {
				let timeout = 100;
				if (this.tt) {
					clearTimeout(this.tt);
					timeout = 500;
				}
				this.tt = setTimeout(this.loadEntries, timeout);
			}
			this.invalidValue = false;
		},
		up() {
			if (this.current === -1) {
				this.current = this.currentNumberOfSuggestions - 1;
				this.useSelection = true;
			} else if (this.current === 0) {
				this.current -= 1;
				this.useSelection = false;
			} else {
				this.current -= 1;
				this.useSelection = true;
			}
		},
		down() {
			if (!this.values) {
				return;
			}
			if (this.current === this.currentNumberOfSuggestions - 1) {
				this.current = -1;
				this.useSelection = false;
			} else {
				this.current += 1;
				this.useSelection = true;
			}
		},
		isActive(index) {
			return index === this.current;
		},
		enter() {
			if (!this.values[this.current]) {
				this.current = 0;
			}

			if (!this.openSuggestion) {
				return;
			}

			if (this.useSelection && this.valueLabel && this.values[this.current]) {
				this.internalValue = this.values[this.current][this.valueLabel];
				this.internalValueId = this.valueId
					? this.values[this.current][this.valueId]
					: this.values[this.current];
			} else if (
				(this.useSelection || this.mustSelectComputed) &&
				this.values &&
				this.values[this.current]
			) {
				this.internalValue = this.values[this.current];
			}

			this.isOpen = false;
			this.selectedSuggestion = true;
			this.processEvent(); // $event);
			this.$emit(
				'enter',
				this.internalValue,
				this.valueId ? { id: this.internalValueId } : this.internalValueId
			);

			setTimeout(() => this.$emit('blur'), 300);
			this.useSelection = false;
		},
		suggestionClick(value, index?: number) {
			if (this.valueLabel) {
				const val =
					index !== undefined
						? this.values[index]
						: this.values.find(entry => entry[this.valueLabel] === value);
				if (val) {
					this.internalValue = val[this.valueLabel];
					this.internalValueId = this.valueId ? val[this.valueId] : val;
				} else if (this.$scopedSlots?.addCustomValue) {
					this.internalValueId = null;
					this.internalValue = value;
				} else {
					// eslint-disable-next-line no-console
					console.log('value not found', value);
				}
			} else {
				this.internalValue = value;
			}
			this.selectedSuggestion = true;
			this.isOpen = false;
			this.processEvent();
			this.$emit(
				'enter',
				this.internalValue,
				this.valueId ? { id: this.internalValueId } : this.internalValueId
			);
		},
		open() {
			this.$emit('click');
			if (!this.isOpen) {
				this.useSelection = true;
				this.isOpen = true;
			}
			if (!this.values || this.values.length === 0) {
				this.loadEntries();
			}
		},
		focus() {
			if (!this.isOpen) {
				this.open();
			}
			this.$emit('focus');
		},
		async blur($event) {
			this.waitForClick = newDeferredPromise();
			await Promise.race([this.waitForClick?.promise, delay(300)]);

			// reset invalidValue if user "just looking"
			if (
				((!this.value || this.value.length === 0) &&
					(!this.internalValue || this.internalValue.length === 0)) ||
				this.value === this.internalValue
			) {
				this.invalidValue = false;
			}

			if (!this.mustSelectComputed) {
				this.processEvent($event);
			} else if (
				this.mustSelectComputed &&
				!this.selectedSuggestion &&
				!this.$scopedSlots?.addCustomValue
			) {
				this.loadEntries().then(() => {
					const valid = this.values.some(testValue => {
						if (this.valueLabel && this.internalValue === testValue[this.valueLabel]) {
							this.internalValue = testValue[this.valueLabel];
							this.internalValueId = this.valueId ? testValue[this.valueId] : testValue;
							return true;
						}

						if (this.values && this.internalValue === testValue) {
							this.internalValue = testValue;
							return true;
						}

						return false;
					});

					if (!valid && !this.$scopedSlots?.addCustomValue && this.mustSelect) {
						this.invalidValue = true;
					}
				});
			}

			if (
				!this.$scopedSlots?.addCustomValue ||
				(this.$scopedSlots?.addCustomValue && !this.internalValue)
			) {
				this.isOpen = false;
			}

			this.useSelection = false;
			this.$emit('blur', $event);
		},
		processEvent($event?: Event) {
			if (this.waitForClick) {
				this.waitForClick.resolve();
				this.waitForClick = undefined;
			}

			this.invalidValue = false;
			const params = this.valueId ? { id: this.internalValueId } : this.internalValueId;

			const value =
				(!this.mustSelectComputed &&
					!this.useSelection &&
					$event &&
					($event.target as any).value) ||
				this.internalValue;

			this.$emit('input', value, $event, params);
		},
		doAddCustomValue() {
			this.suggestionClick(this.internalValue);
		},
		onInternalValueChanged(newVal) {
			if (newVal && newVal.length === 0) {
				this.isOpen = false;
			}
		},
		onValueChanged(newVal) {
			this.entriesFetched = false;
			this.internalValue = newVal;
		}
	},
	props: {
		id: { type: String, default: 'autocompleteinner', required: true },
		valuePromise: { type: Function, required: true },
		required: { type: Boolean, default: false },
		value: { type: String, default: '' },
		valueId: { type: String, default: '' },
		valueLabel: { type: String, default: '' },
		name: { type: String, default: '' },
		mustSelect: { type: Boolean, default: false },
		allowDuplicateCustomValues: { type: Boolean, default: true },
		fullwidth: {
			type: [String, Boolean],
			default: false,
			validator: (value: string | boolean) => [false, 'mobile', 'always'].includes(value)
		},
		autofocus: { type: Boolean, default: false },
		minLength: { type: Number, default: 2 },
		useOverlay: { type: Boolean, default: true },
		positionInitial: { type: Boolean, default: false },
		maxNumberOfSuggestions: { type: Number, default: 4 },
		disabled: { type: Boolean, default: false },
		placeholder: { type: String, default: '' },
		size: {
			type: String,
			default: 'medium',
			validator: (value: string) => ['medium', 'large'].includes(value)
		}
	},
	watch: {
		internalValue: [
			{
				handler: 'onInternalValueChanged'
			}
		],
		value: [
			{
				handler: 'onValueChanged'
			}
		]
	}
});
